import {
  LocalizedActivityDTO,
  PlanningMode,
} from "@/lib/eduConfigurationServiceClient";
import { MailingExtended, PlannedMailingCreateDTO } from "@/models/mailing";
import { DateTime } from "luxon";

export interface TimingFormValues {
  timingMode: TimingMode;
  relativePlanning: {
    dayOffset?: number;
    timeOfDay?: DateTime;
  };
  datetime?: DateTime;
}

export type MailingTimingDTO = Pick<
  PlannedMailingCreateDTO,
  | "planningMode"
  | "plannedDateTime"
  | "relativePlanningDayOffset"
  | "relativePlanningTimeOfDay"
>;

export const timingModes = [
  "absolute",
  "relativeBefore",
  "relativeAfter",
  "immediately",
] as const;
export type TimingMode = (typeof timingModes)[number];

export function convertToFormValues(
  dto: MailingExtended,
  activity: LocalizedActivityDTO | undefined,
): TimingFormValues {
  return {
    timingMode: mapToTimingMode(dto, activity?.startDateTime),
    relativePlanning: {
      dayOffset: dto.relativePlanningDayOffset
        ? Math.abs(dto.relativePlanningDayOffset)
        : undefined,
      timeOfDay: dto.relativePlanningTimeOfDay,
    },
    datetime: dto.plannedDateTime,
  };
}

function mapToTimingMode(
  dto: MailingExtended,
  activityStartDateTime?: DateTime,
): TimingMode {
  if (!dto.planningMode) {
    return "absolute";
  }
  if (dto.planningMode == PlanningMode.Absolute) {
    return "absolute";
  }
  if (dto.planningMode == PlanningMode.RelativeToActivityStart) {
    if (!activityStartDateTime) {
      throw new Error(
        "Cannot map to timing mode without activity start date/time",
      );
    }
    if (dto.relativePlanningDayOffset ?? 0 < 0) {
      return "relativeBefore";
    }
    if (dto.relativePlanningDayOffset ?? 0 > 0) {
      return "relativeAfter";
    }
    if (dto.relativePlanningDayOffset ?? 0 == 0) {
      if (
        (dto.relativePlanningTimeOfDay?.hour ?? 0) <=
        (activityStartDateTime.hour ?? 0)
      ) {
        return "relativeBefore";
      } else {
        return "relativeAfter";
      }
    }
  }

  return "absolute";
}

export function convertToDto(values: TimingFormValues): MailingTimingDTO {
  switch (values.timingMode) {
    case "absolute":
      return {
        planningMode: PlanningMode.Absolute,
        plannedDateTime: values.datetime,
        relativePlanningDayOffset: undefined,
        relativePlanningTimeOfDay: undefined,
      };
    case "immediately":
      return {
        planningMode: PlanningMode.Absolute,
        plannedDateTime: DateTime.now().plus({ minutes: 1 }),
        relativePlanningDayOffset: undefined,
        relativePlanningTimeOfDay: undefined,
      };
    case "relativeBefore":
      return {
        planningMode: PlanningMode.RelativeToActivityStart,
        plannedDateTime: undefined,
        relativePlanningDayOffset: values.relativePlanning.dayOffset
          ? -values.relativePlanning.dayOffset
          : undefined,
        relativePlanningTimeOfDay:
          values.relativePlanning.timeOfDay?.toFormat("HH:mm") ?? undefined,
      };
    case "relativeAfter":
      return {
        planningMode: PlanningMode.RelativeToActivityStart,
        plannedDateTime: undefined,
        relativePlanningDayOffset: values.relativePlanning.dayOffset,
        relativePlanningTimeOfDay:
          values.relativePlanning.timeOfDay?.toFormat("HH:mm") ?? undefined,
      };
  }
}

export function convertToPlanningMode(TimingMode: TimingMode): PlanningMode {
  if (TimingMode == "immediately") {
    return PlanningMode.Absolute;
  }
  if (TimingMode == "absolute") {
    return PlanningMode.Absolute;
  }
  if (TimingMode == "relativeBefore") {
    return PlanningMode.RelativeToActivityStart;
  }
  if (TimingMode == "relativeAfter") {
    return PlanningMode.RelativeToActivityStart;
  }
  return PlanningMode.Absolute;
}
