<template>
  <Menu
    v-slot="{ close }"
    as="div"
    class="relative flex"
    v-bind="$attrs"
    data-testid="dropdown"
  >
    <slot name="button"></slot>
    <TransitionMenu>
      <MenuItems
        :class="[
          'absolute z-10 group-[:nth-last-of-type(-n+6)]/listitem:bottom-8',
          { 'left-0 origin-top-left': origin === DropdownOrigin.TopLeft },
          { 'right-0 origin-top-right': origin === DropdownOrigin.TopRight },
          {
            'left-1/2 origin-top -translate-x-1/2 transform':
              origin === DropdownOrigin.TopCenter,
          },
        ]"
        @mouseleave="closeAfterTimeout(close)"
        @mouseenter="clearCloseTimeout()"
      >
        <div
          :class="[
            'mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-72',
            { 'divide-y divide-gray-100': divider },
          ]"
        >
          <slot name="items"></slot>
        </div>
      </MenuItems>
    </TransitionMenu>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuItems } from "@headlessui/vue";
import TransitionMenu from "@/components/common/transition/TransitionMenu.vue";
import { DropdownOrigin } from "./Dropdown.types";
import { Timeout } from "@/typings";

withDefaults(defineProps<{ origin?: DropdownOrigin; divider?: boolean }>(), {
  origin: DropdownOrigin.TopLeft,
  divider: false,
});

// By default, a menu will close when the user clicks outside of it,
// but it will also close when the user hovers out of the menu for
// a certain amout of time.

let timeout: Timeout;

const closeAfterTimeout = (close: () => void) => {
  clearCloseTimeout();
  timeout = setTimeout(() => {
    close();
  }, 300);
};

const clearCloseTimeout = () => {
  clearTimeout(timeout);
};
</script>
