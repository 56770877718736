<template>
  <div class="flex flex-col gap-6">
    <Search
      v-model="search"
      :placeholder="texts.navigationItems.organize.searchActivities"
    />
    <div class="flex flex-col gap-6 rounded">
      <div class="flex items-center justify-between gap-1.5">
        <h2 class="text-lg font-medium text-deepteal-700">
          {{ texts.generic.filters }}
        </h2>
        <span
          class="cursor-pointer text-xs text-emerald-500 underline hover:text-emerald-700"
          @click="resetFilters"
          >{{ texts.actions.reset }}</span
        >
      </div>
      <ActivityStatusFilter
        :reset="resetActivityStatusFilter"
        :counts="activityStatusFilter.counts"
        @filter="(values) => (activityStatusFilter.ids = values)"
      />
      <ActivityTypeFilter
        :activityTypes="activityTypes"
        :reset="resetActivityTypeFilter"
        :counts="activityTypeFilter.counts"
        @filter="(values) => (activityTypeFilter.ids = values)"
      />
      <Divider />
      <StudyProgramFilter
        :studyPrograms="studyPrograms"
        :counts="studyProgramFilter.counts"
        :reset="resetStudyProgramFilter"
        @filter="(values) => (studyProgramFilter.ids = values)"
      ></StudyProgramFilter>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { SessionStorageKey } from "@/enums";
import Search from "@/components/common/search/Search.vue";
import { LocalizedStudyProgramDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import Activity from "@/models/activity";
import ActivityTypeFilter from "./ActivityTypeFilter.vue";
import ActivityType from "@/models/activityType";
import Divider from "@/components/common/divider/Divider.vue";
import { countBy } from "@/utils/array";
import StudyProgramFilter from "@/components/study-programs/StudyProgramFilter.vue";
import {
  compare,
  compareArray,
} from "@/components/common/filter/FilterItem.types";
import ActivityStatusFilter from "@/views/organize/activities/components/ActivityStatusFilter.vue";

const props = defineProps<{
  activities: Activity[];
  activityTypes: ActivityType[];
  studyPrograms: LocalizedStudyProgramDTO[];
}>();

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.ActivitiesSearchTerm) || undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(SessionStorageKey.ActivitiesSearchTerm, newValue)
    : sessionStorage.removeItem(SessionStorageKey.ActivitiesSearchTerm),
);

const emit = defineEmits<{
  (e: "filter", value: Activity[]): void;
}>();

const activityStatusFilter = reactive({
  ids: undefined as string[] | undefined,
  counts: computed(() => countBy(props.activities, (item) => item.status)),
});

const activityTypeFilter = reactive({
  ids: undefined as string[] | undefined,
  counts: computed(() =>
    countBy(props.activities, (item) => item.activityTypeId),
  ),
});

const studyProgramFilter = reactive({
  ids: undefined as string[] | undefined,
  counts: computed(() =>
    countBy(props.activities, (item) => item.studyProgramIds),
  ),
});

const studyMetadataFilter = reactive({
  ids: undefined as string[] | undefined,
});

const resetActivityStatusFilter = ref(false);
const resetActivityTypeFilter = ref(false);
const resetStudyProgramFilter = ref(false);
const resetFilters = () => {
  resetActivityStatusFilter.value = !resetActivityStatusFilter.value;
  resetActivityTypeFilter.value = !resetActivityTypeFilter.value;
  resetStudyProgramFilter.value = !resetStudyProgramFilter.value;
};

const filteredActivities = computed<Activity[]>(() => {
  return props.activities
    .filter(
      (activity) =>
        !search.value ||
        activity.name?.toLowerCase().includes(search.value.toLowerCase()),
    )
    .filter((activity) => compare(activityStatusFilter.ids, activity.status))
    .filter((activity) =>
      compare(activityTypeFilter.ids, activity.activityTypeId),
    )
    .filter((activity) =>
      compareArray(studyProgramFilter.ids, activity.studyProgramIds || []),
    )
    .filter((activity) =>
      compareArray(studyMetadataFilter.ids, activity.studyProgramIds || []),
    );
});

watch(filteredActivities, (value) => emit("filter", value), {
  immediate: true,
});
</script>
