import { ActivityRelatedMailingOverviewDTO } from "@/lib/eduConfigurationServiceClient";

export const plannedSortFn = (
  left: ActivityRelatedMailingOverviewDTO,
  right: ActivityRelatedMailingOverviewDTO,
) => {
  return left.plannedDateTime &&
    right.plannedDateTime &&
    left.plannedDateTime < right.plannedDateTime
    ? -1
    : 1;
};

export const sentSortFn = (
  left: ActivityRelatedMailingOverviewDTO,
  right: ActivityRelatedMailingOverviewDTO,
) => {
  return left.sentDateTime &&
    right.sentDateTime &&
    left.sentDateTime < right.sentDateTime
    ? 1
    : -1;
};

export const conceptSortFn = (
  left: ActivityRelatedMailingOverviewDTO,
  right: ActivityRelatedMailingOverviewDTO,
) => {
  if (!left.modifiedDateTime) return 1;
  if (!right.modifiedDateTime) return -1;

  return left.modifiedDateTime < right.modifiedDateTime ? 1 : -1;
};
