import {} from "@/lib/eduConfigurationServiceClient";
import {
  MailingTypeGroup,
  toMailingTypeGroups,
} from "@/components/mailing-type-picker/MailingTypePicker.types";
import { MailingType as MailingTypeEnum } from "@/enums";
import settings from "@/store/context/settings.context";

export const getDashboardSupportedMailingTypes = (): MailingTypeGroup[] => {
  const supportedMailingTypes = [
    MailingTypeEnum.General,
    MailingTypeEnum.ActivityInvite,
  ];

  return toMailingTypeGroups(
    settings.mailingTypes.filter((item) =>
      supportedMailingTypes.includes(item.mailingType),
    ),
    {
      useDashboardSelection: true,
      returnUrl: undefined,
    },
  );
};
