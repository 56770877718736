<template>
  <div v-show="props.show" class="flex flex-col gap-4">
    <div
      class="flex flex-col gap-8 xl:flex-row xl:items-center xl:justify-between"
    >
      <h3 class="text-lg font-semibold text-deepteal-500">
        {{ activityContext.activityLocations?.locations.length }}
        {{ texts.generic.results }}
      </h3>

      <div
        class="flex items-center justify-end gap-4 text-sm font-normal text-deepteal-500 underline"
      >
        <button
          type="button"
          class="hover:text-deepteal-700"
          @click="updateAllSelected(true)"
        >
          {{ texts.actions.selectAll }}
        </button>
        <button
          type="button"
          class="hover:text-deepteal-700"
          @click="updateAllSelected(false)"
        >
          {{ texts.actions.clearAll }}
        </button>
      </div>
    </div>

    <List>
      <ListItem
        v-for="location in activityContext.activityLocations?.locations"
        :key="location.id"
        :class="[
          'h-10 border-l-2 transition-colors duration-75',
          selectedIds.includes(location.id)
            ? 'border-deepteal-500'
            : 'border-transparent',
        ]"
        :data-testid="testIds.activityLocations.item"
      >
        <ListItemColumn :flex="2">
          <label class="flex w-full items-center gap-4">
            <Checkbox
              :id="location.id"
              v-model="selectedIds"
              :data-testid="testIds.activityLocations.item"
              name="activity-locations"
              :value="location.id"
            />
            <span class="truncate">
              {{ location.name }}
            </span>
          </label>
        </ListItemColumn>
        <ListItemColumn
          v-if="selectedIds.includes(location.id)"
          class="cursor-pointer self-end"
          @click="() => openScanConfigurationSlideOver(location.id)"
        >
          <Icon icon="qr_code_2" :color="Color.DeepTeal" />
          <span>
            {{ componentTexts.scanConfiguration }}
          </span>
        </ListItemColumn>
      </ListItem>
    </List>

    <SlideOverScanConfiguration
      v-if="activityContext.activity && selectedLocation"
      :key="selectedLocation.id"
      v-model:visible="slideOverScanConfigurationOpen"
      :activity="activityContext.activity"
      :location="selectedLocation"
    />
  </div>
</template>

<script setup lang="ts">
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import Icon from "@/components/common/icon/Icon.vue";
import List from "@/components/common/list/List.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { Color } from "@/enums";
import { ActivityLocationLocalizedDTO } from "@/lib/eduConfigurationServiceClient";
import activityContext from "@/store/context/activity.context";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import SlideOverScanConfiguration from "@/views/organize/activities/activity/locations/components/SlideOverScanConfiguration.vue";
import { ref } from "vue";

const props = defineProps<{
  show: boolean;
}>();

const selectedIds = defineModel<string[]>({ required: true });

const componentTexts = texts.navigationItems.organize.activity.locations;

const updateAllSelected = (value: boolean) => {
  const locations =
    activityContext.activityLocations?.locations.map((al) => al.id) ?? [];

  selectedIds.value = selectedIds.value
    .filter((id) => !locations.some((al) => al === id))
    .concat(value ? locations : []);
};

const slideOverScanConfigurationOpen = ref(false);
const selectedLocation = ref<ActivityLocationLocalizedDTO>();
const openScanConfigurationSlideOver = (locationId: string) => {
  slideOverScanConfigurationOpen.value = true;

  selectedLocation.value = activityContext.activityLocations?.locations.find(
    (location) => location.id === locationId,
  );
};
</script>
