import Quill from "quill";
import Scroll from "quill/blots/scroll";

import Container from "quill/blots/container";
import ListItem from "quill/formats/list";

class UnorderedListContainer extends Container {
  static blotName = "unordered-list-container";
  static tagName = "UL";
}

export default class UnorderedListItem extends ListItem {
  static blotName = "unordered-list";
  static tagName = "LI";
  static icon: string =
    '<svg viewbox="0 0 18 18"><line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"/><line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"/><line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"/><line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"/><line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"/><line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"/></svg>';

  static register() {
    Quill.register(UnorderedListContainer);
  }

  constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);
  }
}
UnorderedListContainer.allowedChildren = [UnorderedListItem];
UnorderedListItem.requiredContainer = UnorderedListContainer;
