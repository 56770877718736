<template>
  <Collapsible
    v-model:expanded="expandedInternal"
    class="-m-1 overflow-hidden p-1"
  >
    <template v-slot>
      <Chevron
        :expanded="expandedInternal"
        @click="expandedInternal = !expandedInternal"
      />
      <button type="button" @click="expandedInternal = !expandedInternal">
        <h4 class="font-bold text-deepteal-700">{{ title }}</h4>
      </button>
    </template>
    <template v-slot:expanded>
      <slot></slot>
    </template>
  </Collapsible>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import Chevron from "@/components/common/chevron/Chevron.vue";

const props = defineProps<{
  title: string;
  expanded: boolean;
}>();

const emit = defineEmits<{
  "update:expanded": [value: boolean];
}>();

const expandedInternal = computed({
  get: () => props.expanded,
  set: (value) => emit("update:expanded", value),
});
</script>
