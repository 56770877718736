<template>
  <form @submit="submitForm">
    <div class="flex h-full flex-col gap-8">
      <div class="flex w-full items-start justify-between">
        <div class="flex flex-col gap-2">
          <PageHeading>{{ componentTexts.locations.sectionTitle }}</PageHeading>
          <PageSubTitle>{{
            componentTexts.locations.sectionDescription
          }}</PageSubTitle>
        </div>

        <div v-if="activityContext.activity">
          <ButtonSubmit
            v-if="activityContext.registrationLinks.length === 0"
            :data-testid="testIds.action.submit"
            :loading="actionLoading"
            :trailingIcon="'arrow_right_alt'"
          >
            <span class="inline-flex flex-wrap items-center gap-1"
              ><span class="font-semibold">{{ texts.actions.submit }},</span>
              <span class="font-normal">{{
                componentTexts.locations.btnToRegistrationLinks
              }}</span></span
            >
          </ButtonSubmit>
          <ButtonSubmit
            v-else
            :data-testid="testIds.action.submit"
            :loading="actionLoading"
          />
        </div>
      </div>

      <div class="flex flex-col gap-8">
        <div class="flex w-full max-w-2xl flex-col gap-8">
          <FormFieldToggle
            id="isOnline"
            :disabled="activityContext.activityLocations?.canSwitchIsOnlineMode"
            :label="texts.navigationItems.organize.activity.form.isOnline"
            :displayMode="FormFieldDisplayMode.Row"
          />

          <FormFieldTextInput
            v-show="form.values.isOnline"
            id="onlineUrl"
            :label="texts.navigationItems.organize.activity.form.onlineUrl"
            :type="TextInputType.URL"
            :displayMode="FormFieldDisplayMode.Row"
          />
        </div>

        <FormField
          id="locationIds"
          :initialValue="activityContext.activity?.locationIds ?? []"
        >
          <template v-slot="{ value }">
            <LocationsForm
              v-model="value.value"
              :show="!form.values.isOnline"
            />
          </template>
        </FormField>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useForm } from "vee-validate";
import * as yup from "yup";

import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import LocationsForm from "./components/LocationItems.vue";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import {
  ActivityLocationFormValues,
  convertToFormValues,
  convertToDTO,
} from "./components/ActivityLocations.types";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { UpdateActivityLocationsDTO } from "@/lib/eduConfigurationServiceClient";
import activityContext from "@/store/context/activity.context";
import { loadActivityLocations } from "@/store/context/activity.context";
import router from "@/router/index";
import texts from "@/utils/texts";
import { testIds } from "@/utils/testing";
import Notify from "@/utils/notify";
import FormField from "@/components/common/form/FormField.vue";

const props = defineProps<ActivityRouteProps>();

const componentTexts = texts.navigationItems.organize.activity;
const validationTexts = texts.navigationItems.organize.activity.locations.form;

const formValues = ref<ActivityLocationFormValues>();
formValues.value = convertToFormValues(props.activity);

// Form configuration
const validationSchema = yup.object<ActivityLocationFormValues>({
  isOnline: yup.boolean().required(),
  onlineUrl: yup.string().when("isOnline", {
    is: true,
    then: (schema) =>
      schema
        .url(validationTexts.invalidUrlDescription)
        .required(validationTexts.urlIsRequired),
  }),
  locationIds: yup.array().when("isOnline", {
    is: false,
    then: (schema) => schema.min(1, validationTexts.physicalLocationRequired),
  }),
});

const form = useForm<ActivityLocationFormValues>({
  validationSchema,
  initialValues: formValues.value,
});

// Form actions
const actionLoading = ref<boolean>(false);

const submitForm = form.handleSubmit((values) => {
  updateActivityLocations(values);
});

// Location form actions
const fetchActivityLocations = async () => {
  try {
    actionLoading.value = true;

    activityContext.activity =
      await eduConfigurationServiceClient.getActivityById(
        activityContext.activity!.id,
      );

    if (!activityContext.activityLocations) {
      await loadActivityLocations(activityContext.activity!.id);
    }
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.activity.title,
    ]);
    throw err;
  } finally {
    actionLoading.value = false;
  }
};

onMounted(() => {
  fetchActivityLocations();
});

const updateActivityLocations = async (values: ActivityLocationFormValues) => {
  try {
    actionLoading.value = true;

    await eduConfigurationServiceClient.updateActivityLocations(
      activityContext.activity!.id,
      convertToDTO(values) as UpdateActivityLocationsDTO,
    );

    Notify.success(texts.notifications.edit.success, [
      texts.models.activity.title,
    ]);

    fetchActivityLocations();

    if (activityContext.registrationLinks.length === 0) {
      router.push({
        name: RouteNamesActivityOverview.REGISTRATIONLINKS,
        params: { id: activityContext.activity!.id },
      });
    }
  } catch (err) {
    Notify.failure(texts.notifications.edit.failure, [
      texts.models.activity.title,
    ]);
    throw err;
  } finally {
    actionLoading.value = false;
  }
};
</script>
