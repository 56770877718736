<template>
  <HeadlessCombobox
    v-model="selectedIds"
    as="div"
    multiple
    :disabled="disabled"
  >
    <ul
      v-if="selectedOptions && selectedOptions.length > 0 && !loading"
      class="flex flex-wrap items-center gap-2"
    >
      <li v-for="selected in selectedOptions" :key="selected.id">
        <TagRemovable
          :color="Color.Royal"
          :size="TagSize.xs"
          @remove="removeSelectedItem(selected)"
          >{{ formatOptionDisplayString(selected) }}
        </TagRemovable>
      </li>
    </ul>

    <div class="relative mt-1">
      <ComboboxInput
        :id="id"
        :name="name"
        :class="inputClass"
        :displayValue="(option) => query"
        :placeholder="texts.actions.search"
        autocomplete="off"
        @change="query = $event.target.value"
        @click="clickInput"
      />

      <ComboboxButton
        ref="comboBoxButton"
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        @click="wasSelectorClicked = true"
      >
        <Spinner
          v-if="loading && (!!query || wasSelectorClicked)"
          :size="IconSize.sm"
        />
        <Icon
          v-else
          icon="unfold_more"
          aria-hidden="true"
          :size="IconSize.sm"
        />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredOptions && filteredOptions.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="option in filteredOptions"
          :key="option.id"
          v-slot="{ active, selected }"
          :value="option.id"
          as="template"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4',
              active ? 'bg-emerald-500 text-white' : 'text-gray-900',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ formatOptionDisplayString(option) }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                active ? 'text-white' : 'text-emerald-500',
              ]"
            >
              <Icon icon="check" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-else-if="!!query"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption :key="0" disabled as="template" value="0">
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900',
            ]"
          >
            <span :class="['block truncate']">
              {{ texts.navigationItems.organize.noResultsFound }}
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </HeadlessCombobox>
</template>

<script setup lang="ts" generic="OptionType extends { id: string }">
import { ref, computed } from "vue";
import {
  Combobox as HeadlessCombobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import TagRemovable from "@/components/common/tag/TagRemovable.vue";
import Icon from "@/components/common/icon/Icon.vue";
import { Color } from "@/enums";
import { TagSize } from "@/components/common/tag/Tag.types";
import texts from "@/utils/texts";

const props = withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    valid?: boolean;
    disabled?: boolean;
    icon?: string;
    options?: OptionType[];
    formatOptionDisplayString: (option: OptionType) => string;
    searchFilter: (option: OptionType, query: string) => boolean;
    searchResultsCompare?: (left: OptionType, right: OptionType) => number;
    modelValue?: string[];
    loading?: boolean;
  }>(),
  {
    loading: false,
    id: undefined,
    name: undefined,
    icon: undefined,
    options: undefined,
    searchResultsCompare: undefined,
    modelValue: undefined,
  },
);

const comboBoxButton = ref<InstanceType<typeof ComboboxButton> | null>(null);
const clickInput = () => {
  const buttonElement = comboBoxButton.value?.$el;
  if (buttonElement instanceof HTMLElement) {
    buttonElement.click();
  }
};

const emit = defineEmits<{
  (e: "update:modelValue", value: string[] | undefined): void;
}>();

const displayInvalid = computed(() => props.valid === false);
const inputClass = computed(() => [
  "block w-full sm:text-sm rounded-md",
  {
    "border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100":
      props.disabled && !displayInvalid.value,
    "border-alert-300 text-gray-500 placeholder-gray-300 bg-gray-100":
      props.disabled && displayInvalid.value,
    "border-gray-300 text-black placeholder-gray-500 focus:ring-emerald-500 focus:border-emerald-500":
      !props.disabled && !displayInvalid.value,
    "border-alert-300 text-alert-900 placeholder-alert-300 focus:outline-none focus:ring-alert-500 focus:border-alert-500":
      !props.disabled && displayInvalid.value,
    "pl-10": props.icon,
  },
]);

const query = ref("");

const selectedIds = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const selectedOptions = computed(() =>
  props.options?.filter((option) => selectedIds.value?.includes(option.id)),
);

const filteredOptions = computed(() => {
  let intermediateResults: OptionType[] | undefined;

  if (query.value === "") {
    intermediateResults = props.options;
  } else {
    intermediateResults = props.options?.filter((option) =>
      props.searchFilter(option, query.value),
    );
  }

  if (props.searchResultsCompare && intermediateResults) {
    return intermediateResults.toSorted(props.searchResultsCompare);
  }

  return intermediateResults;
});

const removeSelectedItem = (option: OptionType) => {
  selectedIds.value?.splice(selectedIds.value.indexOf(option.id), 1);

  if (selectedIds.value?.length === 0) {
    selectedIds.value = undefined;
  }
};

const wasSelectorClicked = ref(false);
</script>
