import { Culture, MailingType as MailingTypeEnum } from "@/enums";
import TargetAudience from "@/models/targetAudience";
import { FromEmailAddress, ReplyToEmailAddress } from "@/models/email-address";
import { Form } from "@/models/form";
import {
  ConceptMailingCreateDTO,
  ConceptMailingUpdateDTO,
  MailingExtended,
  PlannedMailingCreateDTO,
  PlannedMailingUpdateDTO,
} from "@/models/mailing";
import { isActivityMailingType, MailingType } from "@/models/mailingType";
import { RegistrationLink } from "@/models/registrationLink";
import { getRegistrationLinks } from "@/services/registrationLink.service";
import { LocalizedQuestionWithDiscreteAnswers } from "@/lib/formsServiceClient";
import {
  LocalizedActivityDTO,
  MailingSelectionDefinitionDto,
  PreEducationLevelDTO,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
  ThirdPartyProspectSourceDto,
  ActivityVisitedSurveyLinkOverviewDTO,
  PlanningMode,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { IEntityContentJson } from "@beefree.io/sdk/dist/types/bee";
import {
  convertToDto,
  convertToFormValues,
  MailingTimingDTO,
  TimingFormValues,
} from "@/views/mailings/mailing/steps/Timing.types";
import { DateTime } from "luxon";
import { calculateSendDateTime } from "@/views/mailings/mailing/components/MailingCalculatedSendDateTime.types";

export type ConceptMailingData = Partial<CompleteMailingData> & {
  type: MailingTypeEnum;
  locale: Culture;
};

export type CompleteMailingData = {
  type: MailingTypeEnum;
  locale: Culture;
  name: string;
  activity?: LocalizedActivityDTO;
  registrationLink?: RegistrationLink;
  surveyLink?: ActivityVisitedSurveyLinkOverviewDTO;
  subject: string;
  fromName: string;
  fromEmailAddress: string;
  replyToEmailAddress: string;
  mailingContent: {
    configuration: IEntityContentJson;
    content: string;
  };
  selectionDefinition: SelectionDefinitionDto;
  timingFormValues: TimingFormValues;
};

export type MailingContextData = {
  id?: string; // Id of the mailing. Should not be here but reactivity is weird on the ConceptMailingData
  activities: LocalizedActivityDTO[];
  fromEmailAddresses: FromEmailAddress[];
  replyToEmailAddresses: ReplyToEmailAddress[];
  mailingType: MailingType;
  surveyForms: Form[];
  targetAudiences: TargetAudience[];
  languages: Culture[];
  questionsWithAnswers: LocalizedQuestionWithDiscreteAnswers[];
  mailings: MailingSelectionDefinitionDto[];
  preEducationLevels: PreEducationLevelDTO[];
  thirdPartyProspectSources: ThirdPartyProspectSourceDto[];
  disableActivitySelection: boolean;
};

export const convertToMailingConceptData = async (
  mailing: MailingExtended,
  availableActivities: LocalizedActivityDTO[],
): Promise<ConceptMailingData> => {
  const activity = availableActivities.find(
    (act) => act.id === mailing.activityId,
  );

  let registrationLink;
  if (
    !!mailing.activityId &&
    mailing.type === MailingTypeEnum.ActivityInvite &&
    !!mailing.registrationLinkId
  ) {
    registrationLink = (await getRegistrationLinks(mailing.activityId)).filter(
      (registrationLink) => registrationLink.id === mailing.registrationLinkId,
    )[0];
  }

  let surveyLink;
  if (
    !!mailing.activityId &&
    mailing.type === MailingTypeEnum.ActivityVisitedSurvey &&
    !!mailing.surveyLinkId
  ) {
    surveyLink = (
      await eduConfigurationServiceClient.getSurveyLinkOverview(
        mailing.activityId,
      )
    ).filter((surveyLink) => surveyLink.id === mailing.surveyLinkId)[0];
  }

  let selectionDefinition: SelectionDefinitionDto | undefined;
  if (mailing.selectionDefinitionId) {
    selectionDefinition =
      await eduConfigurationServiceClient.getSelectionDefinition(
        mailing.selectionDefinitionId,
      );
  }

  return {
    type: mailing.type,
    locale: mailing.locale,
    name: mailing.name,
    activity: activity,
    registrationLink: registrationLink,
    surveyLink: surveyLink,
    subject: mailing.subject,
    fromName: mailing.fromName,
    fromEmailAddress: mailing.fromEmailAddress,
    replyToEmailAddress: mailing.replyToEmailAddress,
    mailingContent: mailing.mailingContent
      ? {
          configuration: JSON.parse(
            mailing.mailingContent.configuration,
          ) as IEntityContentJson,
          content: mailing.mailingContent.content,
        }
      : undefined,
    selectionDefinition: selectionDefinition,
    timingFormValues: convertToFormValues(mailing, activity),
  };
};

export const convertToConceptMailingCreateDTO = (
  conceptData: ConceptMailingData,
  selectionDefinitionId?: string,
): ConceptMailingCreateDTO => {
  if (!conceptData.name || !conceptData.type)
    throw new Error(
      "Cannot convert to create-dto. name or type of mailing was not provided.",
    );

  const timingDto = conceptData.timingFormValues
    ? convertToDto(conceptData.timingFormValues)
    : undefined;

  return {
    name: conceptData.name,
    type: conceptData.type,
    locale: conceptData.locale,
    activityId: conceptData.activity?.id,
    registrationLinkId: conceptData.registrationLink?.id,
    surveyLinkId: conceptData.surveyLink?.id,
    fromEmailAddress: conceptData.fromEmailAddress,
    replyToEmailAddress: conceptData.replyToEmailAddress,
    fromName: conceptData.fromName,
    mailingContent:
      conceptData.mailingContent &&
      conceptData.mailingContent.configuration &&
      conceptData.mailingContent.content
        ? {
            configuration: JSON.stringify(
              conceptData.mailingContent.configuration,
            ),
            content: conceptData.mailingContent.content,
          }
        : undefined,
    subject: conceptData.subject,
    selectionDefinitionId,
    ...timingDto,
  };
};

export const convertToConceptMailingUpdateDTO = (
  id: string,
  conceptData: ConceptMailingData,
  selectionDefinitionId?: string,
): ConceptMailingUpdateDTO => {
  return {
    ...convertToConceptMailingCreateDTO(conceptData, selectionDefinitionId),
    id,
  };
};

export const convertToPlannedMailingCreateDTO = (
  completeData: CompleteMailingData,
  selectionDefinitionId: string,
): PlannedMailingCreateDTO => {
  const timingDto = convertToDto(completeData.timingFormValues);
  validateMailingTiming(timingDto, completeData.activity?.startDateTime);

  return {
    name: completeData.name,
    type: completeData.type,
    locale: completeData.locale,
    activityId: completeData.activity?.id,
    registrationLinkId: completeData.registrationLink?.id,
    surveyLinkId: completeData.surveyLink?.id,
    fromEmailAddress: completeData.fromEmailAddress,
    replyToEmailAddress: completeData.replyToEmailAddress,
    fromName: completeData.fromName,
    mailingContent: {
      configuration: JSON.stringify(completeData.mailingContent.configuration),
      content: completeData.mailingContent.content,
    },
    subject: completeData.subject,
    selectionDefinitionId,
    ...timingDto,
  };
};

export const convertToPlannedMailingUpdateDTO = (
  id: string,
  completeData: CompleteMailingData,
  selectionDefinitionId: string,
): PlannedMailingUpdateDTO => {
  return {
    ...convertToPlannedMailingCreateDTO(completeData, selectionDefinitionId),
    id,
  };
};

export function createNewDefinitionForMailingType(
  mailingType: MailingType,
  existingDefinition?: SelectionDefinitionDto,
  mailingActivityId?: string,
) {
  if (isActivityMailingType(mailingType.mailingType) && !mailingActivityId)
    throw new Error(
      "No activity id provided for selection of an activity-aware mailing-type",
    );

  const requiredCriteria =
    mailingType.selectionDefinitionAttributes?.requiredCriteria;

  const nonRequiredCriteria =
    mailingType.selectionDefinitionAttributes?.nonRequiredCriteria;

  const newDefinition = new SelectionDefinitionDto({
    responseType: mailingType.selectionDefinitionAttributes.responseType,
    criteria: [],
    filters: {
      studyProgramFilter: {
        studyProgramIds: [],
        activityIds: [],
      },
      activityIds: [],
      dateRange: undefined,
    },
  });

  newDefinition.filters = existingDefinition?.filters ?? newDefinition.filters;

  // We want to re-add any existing criteria that are not
  // in the set of required criteria of our current mailing-type
  if (existingDefinition) {
    newDefinition.criteria = existingDefinition.criteria
      .filter(
        (criterion) =>
          !(criterion.isReadOnly && requiredCriteria.includes(criterion.type)),
      )
      .map(
        (criterion) =>
          new SelectionCriterionDto({ ...criterion, isReadOnly: false }),
      );
  }

  // Add the mailing-type required criteria to the new definition
  newDefinition.criteria = newDefinition.criteria.concat(
    requiredCriteria.map((type) => {
      const canAddActivityId = isActivityCriterion(type) && mailingActivityId;

      return new SelectionCriterionDto({
        type: type,
        isReadOnly: true,
        activityIds: canAddActivityId ? [mailingActivityId] : undefined,
      });
    }),
  );

  // Avoid adding non-required criteria if using an existing definition,
  // as we cannot distinguish between user-added and system-added criteria.
  if (!existingDefinition || existingDefinition.criteria.length === 0)
    newDefinition.criteria = newDefinition.criteria.concat(
      nonRequiredCriteria.map((type) => {
        const canAddActivityId = isActivityCriterion(type) && mailingActivityId;

        return new SelectionCriterionDto({
          type: type,
          isReadOnly: false,
          activityIds: canAddActivityId ? [mailingActivityId] : undefined,
        });
      }),
    );

  newDefinition.criteria.sort((a) => (a.isReadOnly ? -1 : 1));

  return newDefinition;
}

export async function saveOrUpdateSelectionDefinition(
  selectionDefinition: SelectionDefinitionDto,
  selectionDefinitionId?: string,
) {
  if (selectionDefinitionId) {
    return await eduConfigurationServiceClient.updateSelectionDefinition(
      selectionDefinitionId,
      selectionDefinition,
    );
  } else {
    return await eduConfigurationServiceClient.saveSelectionDefinition(
      selectionDefinition,
    );
  }
}

function isActivityCriterion(type: SelectionCriterionDtoType) {
  return (
    type === SelectionCriterionDtoType.HasVisitedActivity ||
    type === SelectionCriterionDtoType.HasNotVisitedActivity ||
    type === SelectionCriterionDtoType.NotSubmittedSurveyForVisitedActivity ||
    type === SelectionCriterionDtoType.IsRegisteredForActivity ||
    type === SelectionCriterionDtoType.IsNotRegisteredForActivity
  );
}

function validateMailingTiming(
  dto: MailingTimingDTO,
  activityStartDateTime?: DateTime,
) {
  const now = DateTime.now();

  if (dto.planningMode === PlanningMode.Absolute) {
    if (!dto.plannedDateTime) {
      throw new Error("Mailing is not planned");
    }
    if (dto.plannedDateTime < now) {
      throw new Error("Mailing has a date in the past");
    }
  } else if (dto.planningMode === PlanningMode.RelativeToActivityStart) {
    if (!activityStartDateTime) {
      throw new Error("No activity start date/time supplied");
    }
    if (!dto.relativePlanningDayOffset || !dto.relativePlanningTimeOfDay) {
      throw new Error("Mailing is not planned");
    }
    const calculatedSendDate = calculateSendDateTime(
      activityStartDateTime,
      dto.relativePlanningDayOffset,
      dto.relativePlanningTimeOfDay,
    );

    if (calculatedSendDate < now) {
      throw new Error("Mailing has a date in the past");
    }
  }
}
