<template>
  <Page :loading="loading" :error="error">
    <div v-if="mailing && mailingData">
      <Section>
        <RouterLinkAuthWithIcon
          data-testid="link-to-mailings-sms"
          :to="{ name: RouteNamesActivityOverview.MAILINGS_SMS }"
          icon="arrow_left_alt"
          :text="texts.actions.backToOverview"
        ></RouterLinkAuthWithIcon>
        <PageHeading
          :title="mailing.name"
          :badgeLabel="
            texts.enums.mailingStatus[mailing.status as MailingStatus]
          "
          :badgeType="statusBadgeType"
        >
          <template v-slot:actions>
            <MailingActionDropdown
              v-if="canWrite"
              :mailing="mailing"
              @editConcept="toMailingEditRoute(mailing.id)"
              @backToConcept:succeeded="fetchMailing"
              @copy:succeeded="(id) => toMailingEditRoute(id)"
              @delete:succeeded="toMailingOverview"
            />
          </template>
        </PageHeading>
      </Section>
      <div v-if="viewMode === 'statistics'">
        <MailingStatistics :mailingId :data="mailingData" />
      </div>
      <div v-else class="flex flex-col gap-8">
        <SectionHeading
          :title="texts.navigationItems.mailing.overview.title"
          :description="texts.navigationItems.mailing.overview.description"
          :divider="false"
        />
        <MailingDescriptionList :data="mailingData" />
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { MailingStatus } from "@/enums";

import { useRouter } from "vue-router";
import logger from "@/plugins/logger";
import {
  RouteNamesActivityOverview,
  RouteNamesMailing,
} from "@/router/routeNames";
import {
  getMailingStatisticsBasicDataFromMailing as getMailingStatistics,
  MailingStatisticsBasicData,
} from "@/views/mailings/mailing/components/MailingStatistics.types";
import {
  getMailingDescriptionListDataFromMailing as getMailingDescriptionList,
  MailingDescriptionListData,
} from "@/views/mailings/mailing/components/MailingDescriptionList.types";

import Page from "@/components/common/page/Page.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import MailingStatistics from "@/views/mailings/mailing/components/MailingStatistics.vue";
import MailingDescriptionList from "@/views/mailings/mailing/components/MailingDescriptionList.vue";
import RouterLinkAuthWithIcon from "@/components/router/router-link-auth/RouterLinkAuthWithIcon.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { convertToBadgeType } from "@/views/mailings/mailing/Overview.types";
import {
  ActivityInviteMailingDTO,
  Permission,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import MailingActionDropdown from "@/views/mailings/mailing/components/MailingActionDropdown.vue";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import HttpClient from "@/utils/httpClient";

const props = defineProps<ActivityRouteProps & { mailingId: string }>();

const router = useRouter();

const canWrite = hasPermissions([Permission.MailingsWrite]);

const mailing = ref<ActivityInviteMailingDTO>();
const mailingData = ref<
  MailingDescriptionListData | MailingStatisticsBasicData
>();
const statusBadgeType = computed<BadgeType>(() =>
  mailing.value
    ? convertToBadgeType(mailing.value.status as MailingStatus)
    : BadgeType.default,
);

const returnUrl = router.resolve({
  name: RouteNamesActivityOverview.MAILINGS_SMS,
  params: {
    id: props.id,
  },
}).href;

const toMailingEditRoute = (id: string) => {
  router.push({
    name: RouteNamesMailing.EDIT,
    params: { id: id },
    query: {
      activityId: props.id,
      returnUrl,
    },
  });
};

const toMailingOverview = () => {
  router.push({
    name: RouteNamesActivityOverview.MAILINGS_SMS,
    params: { statusFilter: mailing.value?.status },
  });
};

// Fetch mail data
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const fetchMailing = () => {
  loading.value = true;
  // @todo: Use eduConfigurationServiceClient instead of HttpClient
  return HttpClient.educonfig
    .get(`/mailings/${props.mailingId}`)
    .then((res) => ActivityInviteMailingDTO.fromJS(res.data))
    .then((mailingFetched) => {
      mailing.value = mailingFetched;

      if (viewMode.value === "statistics") {
        return getMailingStatistics(mailing.value);
      } else {
        return getMailingDescriptionList(mailing.value);
      }
    })
    .then((data) => {
      mailingData.value = data;
    })
    .catch((e) => {
      error.value = true;
      logger.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

fetchMailing();

const viewMode = computed<"statistics" | "description">(() => {
  if (
    mailing.value?.status === MailingStatus.Sent ||
    mailing.value?.status === MailingStatus.InProgress
  )
    return "statistics";
  else return "description";
});
</script>
