<template>
  <Section class="flex max-w-screen-sm flex-col gap-6">
    <template v-slot:heading>
      <SectionHeading
        :title="texts.navigationItems.mailing.steps.timing.title"
        :description="texts.navigationItems.mailing.steps.timing.description"
        :divider="false"
      />
    </template>

    <FormFieldRadio
      :id="formFields.timingMode"
      :items="options"
      :itemLabels="radioButtonLabelDictionary"
    />

    <Card
      v-show="form.values.timingMode === 'absolute'"
      :color="Color.DeepTeal"
      flat
    >
      <FormFieldDateTime
        :id="formFields.datetime"
        :label="texts.models.mailing.timing"
        :displayMode="FormFieldDisplayMode.Column"
        :min="DateTime.now().plus({ minutes: 1 })"
        :max="
          data.type === MailingType.ActivityReminder
            ? data.activity?.endDateTime
            : undefined
        "
      />
    </Card>

    <div
      v-show="
        form.values.timingMode === 'relativeBefore' ||
        form.values.timingMode === 'relativeAfter'
      "
      class="contents"
    >
      <Card :color="Color.DeepTeal" flat class="flex flex-col gap-4">
        <FormFieldTextInput
          id="relativePlanning.dayOffset"
          :label="texts.models.mailing.relativePlanningDayOffset"
          :type="TextInputType.NUMBER"
          :displayMode="FormFieldDisplayMode.Row"
          :min="0"
        />
        <FormFieldTime
          id="relativePlanning.timeOfDay"
          :label="texts.models.mailing.relativePlanningTimeOfDay"
          :displayMode="FormFieldDisplayMode.Row"
        />
      </Card>
      <MailingRelativeSendDateTime
        v-if="
          data.activity?.startDateTime !== undefined &&
          relativeDateOffset !== undefined &&
          form.values.relativePlanning.timeOfDay !== undefined
        "
        :fromDateTime="data.activity?.startDateTime"
        :dayOffset="relativeDateOffset"
        :timeOffset="form.values.relativePlanning.timeOfDay.toFormat('HH:mm')"
      />
      <ErrorMessage v-slot="{ message }" :name="formFields.relativePlanning">
        <span class="text-sm text-alert">{{ message }}</span>
      </ErrorMessage>
    </div>
  </Section>
</template>

<script setup lang="ts">
import Card from "@/components/common/card/Card.vue";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldRadio from "@/components/common/radiobutton/FormFieldRadio.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import FormFieldTime from "@/components/common/time/FormFieldTime.vue";
import { Color, MailingType } from "@/enums";
import { fields } from "@/utils/miscellaneous";
import texts from "@/utils/texts";
import { calculateSendDateTime } from "@/views/mailings/mailing/components/MailingCalculatedSendDateTime.types";
import MailingRelativeSendDateTime from "@/views/mailings/mailing/components/MailingCalculatedSendDateTime.vue";
import {
  ConceptMailingData,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";
import {
  TimingFormValues,
  TimingMode,
} from "@/views/mailings/mailing/steps/Timing.types";
import { DateTime } from "luxon";
import { ErrorMessage, useForm } from "vee-validate";
import { computed, watch } from "vue";
import * as yup from "yup";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

const formFields = fields<TimingFormValues>();
const form = useForm<TimingFormValues>({
  validationSchema: yup.object({
    [formFields.timingMode]: yup.mixed<TimingMode>().required(),
    [formFields.relativePlanning]: yup.object().when([formFields.timingMode], {
      is: (val: TimingMode | undefined) =>
        val == "relativeBefore" || val == "relativeAfter",
      then: (schema) =>
        schema
          .shape({
            dayOffset: yup.number().integer().min(0).required(),
            timeOfDay: yup.mixed<DateTime>().required(),
          })
          .test(
            "relativePlanningDateInFuture",
            texts.validation.dateInFuture,
            () => {
              if (
                internalData.value.activity === undefined ||
                relativeDateOffset.value === undefined ||
                form.values.relativePlanning.timeOfDay === undefined
              ) {
                return true;
              }
              const sendDateTime = calculateSendDateTime(
                internalData.value.activity.startDateTime,
                relativeDateOffset.value,
                form.values.relativePlanning.timeOfDay.toFormat("HH:mm"),
              );
              return sendDateTime > DateTime.now();
            },
          ),
      otherwise: (schema) =>
        schema.shape({
          dayOffset: yup.number().integer().min(0),
          timeOfDay: yup.mixed<DateTime>(),
        }),
    }),
    [formFields.datetime]: yup.mixed<DateTime>().when(formFields.timingMode, {
      is: (val: TimingMode | undefined) => val == "absolute",
      then: (schema) =>
        schema
          .required()
          .test(
            "reminderEndDate",
            texts.validation.reminderDateNeedsToBeBeforeActivityEndDateTime,
            (date) =>
              props.data.type !== MailingType.ActivityReminder ||
              (!!date &&
                !!props.data.activity?.endDateTime &&
                date <= props.data.activity?.endDateTime),
          )
          .test(
            "visitedStartDate",
            texts.validation.surveyDateNeedsToBeAfterActivityStartDateTime,
            (date) =>
              props.data.type !== MailingType.ActivityVisitedSurvey ||
              (!!date &&
                !!props.data.activity?.startDateTime &&
                date > props.data.activity?.startDateTime),
          )
          .isInFuture(texts.validation.dateInFuture),
      otherwise: (schema) => schema,
    }),
  }),
});

const internalData = computed(() => props.data);

const options = computed<TimingMode[]>(() => {
  switch (props.data.type) {
    case MailingType.General:
      return ["absolute", "immediately"];
    case MailingType.Activity:
      return ["absolute", "immediately", "relativeBefore", "relativeAfter"];
    case MailingType.ActivityInvite:
      return ["absolute", "immediately", "relativeBefore", "relativeAfter"];
    case MailingType.ActivityReminder:
      return ["absolute", "immediately", "relativeBefore"];
    case MailingType.ActivityVisitedSurvey:
      return ["absolute", "immediately", "relativeAfter"];
    default:
      return [];
  }
});

const radioButtonLabelDictionary: Record<TimingMode, string> = {
  immediately: texts.models.mailing.timingMode.immediately,
  absolute: texts.models.mailing.timingMode.absolute,
  relativeBefore: texts.models.mailing.timingMode.relativeToActivityStartBefore,
  relativeAfter: texts.models.mailing.timingMode.relativeToActivityStartAfter,
};

const relativeDateOffset = computed(() => {
  if (form.values.relativePlanning.dayOffset === undefined) return undefined;
  if (form.values.timingMode === "relativeBefore")
    return -form.values.relativePlanning.dayOffset;
  if (form.values.timingMode === "relativeAfter")
    return form.values.relativePlanning.dayOffset;
  return undefined;
});

watch(
  () => internalData.value.timingFormValues,
  (values) => {
    form.setValues({ ...values });
  },
  { immediate: true },
);

// Navigation
watch(
  () => props.isNavigatingForward,
  async (value) => {
    if (value) {
      const result = await form.validate();
      if (result.valid) {
        internalData.value.timingFormValues = form.values;
      }

      emit("forwardNavigationValidated", result.valid);
    }
  },
);

watch(
  () => props.isNavigatingBack,
  (value) => {
    if (value) {
      emit("backNavigationValidated", true);
    }
  },
);

watch(
  () => props.isSaving,
  async (value) => {
    if (value) {
      internalData.value.timingFormValues = form.values;

      emit("saveValidated", true);
    }
  },
);
</script>
