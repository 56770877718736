<template>
  <div>
    <label for="search" class="sr-only">{{ placeholder }}</label>
    <div class="relative">
      <TextInput
        v-model="modelValueInternal"
        :data-testid="testIds.search"
        name="search"
        :placeholder="placeholder"
        :type="TextInputType.SEARCH"
        icon="search"
      ></TextInput>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import texts from "@/utils/texts";
import { testIds } from "@/utils/testing";
import { Timeout } from "@/typings";

export interface SearchProps {
  modelValue: string;
  placeholder?: string;
  debounceMs?: number;
}

const props = withDefaults(
  defineProps<{
    modelValue?: string | undefined;
    placeholder?: string;
    debounceMs?: number;
  }>(),
  { modelValue: undefined, placeholder: texts.actions.search, debounceMs: 300 },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string | undefined): void;
}>();

const debounceModelValue = debounce((value: string | undefined) => {
  emit("update:modelValue", value);
}, props.debounceMs);

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => {
    debounceModelValue(value);
  },
});

function debounce<Args>(func: (...args: Args[]) => void, wait: number) {
  // https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
  if (wait === 0) {
    return (...args: Args[]) => {
      func(...args);
    };
  }

  let timeout: Timeout;
  return (...args: Args[]) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
</script>
