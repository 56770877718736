<template>
  <TransitionFade>
    <Card
      v-if="departments && departments.length > 0"
      :size="CardSize.lg"
      class="flex flex-col gap-8"
    >
      <CardHeader :text="componentTexts.departmentInterestsHeader"></CardHeader>
      <div :class="['flex flex-col gap-4', loading ? 'opacity-40' : '']">
        <Collapsible
          v-for="department of departments"
          :key="department.id"
          v-model:expanded="department.expanded"
        >
          <StatsBarListItem
            :count="department.count"
            :max="max"
            :size="StatsBarSize.md"
            :legend="department.title"
            class="flex-1"
          >
            <button
              type="button"
              class="flex items-center gap-2"
              @click="() => clickDepartmentInterest(department)"
            >
              <Chevron :expanded="department.expanded"></Chevron>
              <span class="text-left" :title="department.title">{{
                department.title
              }}</span>
              <TransitionFade>
                <Spinner
                  v-if="department.loading"
                  :color="Color.Lavender"
                  :size="IconSize.xs"
              /></TransitionFade>
            </button>
          </StatsBarListItem>

          <template
            v-if="
              department.studyPrograms && department.studyPrograms.length > 0
            "
            v-slot:expanded
          >
            <StatsBarListItem
              v-for="studyProgram in department.studyPrograms"
              :key="studyProgram.id"
              :count="studyProgram.count"
              :max="max"
              :size="StatsBarSize.sm"
              :intensity="4"
              :legend="studyProgram.title"
              :data-testid="testIds.dashboard.departmentInterestStudyProgram"
            >
              <div class="pl-6 text-left" :title="studyProgram.title">
                {{ studyProgram.title }}
              </div>
            </StatsBarListItem>
          </template>
        </Collapsible>
      </div>
    </Card>
  </TransitionFade>
</template>

<script setup lang="ts">
import { StatsBarSize } from "@/components/common/stats-bar/StatsBar.types";

import { computed, ref, watch } from "vue";
import Spinner from "@/components/common/spinner/Spinner.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import logger from "@/plugins/logger";
import { testIds } from "@/utils/testing";
import { Color } from "@/enums";
import StatsBarListItem from "@/components/common/stats-bar/StatsBarListItem.vue";
import Collapsible from "@/components/common/collapsible/Collapsible.vue";
import Chevron from "@/components/common/chevron/Chevron.vue";
import TransitionFade from "@/components/common/transition/TransitionFade.vue";
import Card from "@/components/common/card/Card.vue";
import CardHeader from "@/components/common/card/CardHeader.vue";
import { CardSize } from "@/components/common/card/Card.types";
import texts from "@/utils/texts";
import {
  ISelectionDefinitionDto,
  SelectionDto,
} from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";

interface DepartmentStat {
  id: string;
  title: string;
  count: number;
  loading: boolean;
  expanded: boolean;
  studyPrograms?: { id?: string; title: string; count: number }[];
}

const props = defineProps<{
  selection?: ISelectionDefinitionDto;
}>();

const componentTexts = texts.navigationItems.dashboard;

const loading = ref(false);

const departments = ref<DepartmentStat[]>();

const max = computed(() =>
  departments.value
    ? Math.max(...departments.value.map((department) => department.count))
    : 0,
);

const initialize = async () => {
  if (!props.selection) {
    return;
  }

  loading.value = true;
  departments.value = undefined;
  try {
    departments.value = [];
    const response =
      await eduConfigurationServiceClient.getProspectCountPerDepartment(
        new SelectionDto(props.selection),
      );

    departments.value = response.departments
      .filter((dto) => dto.count > 0)
      .map(
        (department) =>
          ({
            id: department.id ?? "",
            title:
              department.displayName == "unknown"
                ? texts.generic.unknown
                : department.displayName,
            count: department.count,
            loading: false,
            expanded: false,
          }) satisfies DepartmentStat,
      );
  } catch (e) {
    logger.error(e);
  } finally {
    loading.value = false;
  }
};

const clickDepartmentInterest = async (department: DepartmentStat) => {
  department.expanded = !department.expanded;

  if (department.studyPrograms) {
    return;
  }

  department.loading = true;
  try {
    if (department.id && props.selection) {
      const response =
        await eduConfigurationServiceClient.getProspectCountByDepartment(
          department.id,
          new SelectionDto(props.selection),
        );

      department.studyPrograms = response.studyPrograms.map((studyProgram) => ({
        id: studyProgram.id,
        title: studyProgram.displayName,
        count: studyProgram.count,
      }));
    } else {
      const response =
        await eduConfigurationServiceClient.getProspectCountForUnknownDepartment(
          new SelectionDto(props.selection),
        );

      department.studyPrograms = response.studyPrograms.map((studyProgram) => ({
        id: studyProgram.id,
        title: studyProgram.displayName,
        count: studyProgram.count,
      }));
    }
  } catch (e) {
    logger.error(e);
  } finally {
    department.loading = false;
  }
};

watch(
  () => props.selection?.criteria,
  () => {
    initialize();
  },
  { immediate: true, deep: true },
);
</script>
