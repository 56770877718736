<template>
  <input
    v-model="modelValue"
    type="radio"
    :value
    :disabled
    class="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-500"
  />
</template>

<script setup lang="ts" generic="T extends string">
defineProps<{
  value?: T;
  disabled?: boolean;
}>();

const modelValue = defineModel<T>();
</script>
