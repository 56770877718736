<template>
  <ListItem :title="item.studyProgramDisplayName">
    <ListItemColumn :flex="0">
      <Icon icon="hotel_class" :color="color" />
    </ListItemColumn>
    <ListItemColumn :flex="6" accent>
      <span class="truncate">{{ label }}</span>
    </ListItemColumn>
    <ListItemColumn hide class="justify-end">
      <span :class="getTextColor(color)" class="text-base font-semibold">
        {{ item.prospectCount }}
      </span>
    </ListItemColumn>
    <ListItemColumn class="justify-end" :flex="0">
      <ListItemActions>
        <DropdownItem v-if="item.studyProgramId" @click="copyToClipboard">
          <IconAsync
            icon="link"
            :color="Color.DeepTeal"
            :state="state"
          ></IconAsync>
          <span>{{ texts.actions.copyLink }}</span>
        </DropdownItem></ListItemActions
      >
    </ListItemColumn>
  </ListItem>
</template>

<script setup lang="ts">
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { computed } from "vue";
import { ApplicationsOverviewItem } from "../Overview.types";
import { Color, FormType } from "@/enums";
import texts from "@/utils/texts";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import { formsServiceClient } from "@/services/formsService.client.service";

const props = defineProps<{
  item: ApplicationsOverviewItem;
  color: Color;
}>();

const label = computed(() => props.item.studyProgramDisplayName);
import { useAsyncState } from "@/components/common/async/Async.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { getTextColor } from "@/utils/color";
import Icon from "@/components/common/icon/Icon.vue";

const applicationsTexts = texts.navigationItems.applications;

const createCopyToClipboardUrl = async () => {
  const forms = await formsServiceClient.getForms(FormType.Application);
  const formUri = forms[0]?.uri;

  if (!formUri) {
    throw new Error(`Could not find application form uri!`);
  }

  const url = await formsServiceClient.getPreselectApplicationLink(
    formUri,
    props.item.studyProgramId,
  );
  await navigator.clipboard.writeText(url);
};

const { state, handler: copyToClipboard } = useAsyncState(
  createCopyToClipboardUrl,
  applicationsTexts.copyToClipboard,
  undefined,
  true,
);
</script>
