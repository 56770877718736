import {
  ActivityDTO,
  IUpdateActivityLocationsDTO,
} from "@/lib/eduConfigurationServiceClient";

export type ActivityLocationFormValues = {
  isOnline: boolean;
  onlineUrl: string;
  locationIds: string[];
};

export function convertToFormValues(
  activity: ActivityDTO | undefined,
): ActivityLocationFormValues {
  return {
    isOnline: activity?.isOnline ?? false,
    onlineUrl: activity?.onlineUrl ?? "",
    locationIds: activity?.locationIds ?? [],
  };
}

export function convertToDTO(
  formValues: ActivityLocationFormValues,
): IUpdateActivityLocationsDTO {
  return {
    isOnline: formValues.isOnline,
    onlineUrl: formValues.onlineUrl,
    locationIds: formValues.locationIds,
  };
}
