import { DateTime } from "luxon";

export function calculateSendDateTime(
  fromDateTime: DateTime,
  dayOffset: number,
  timeOffset: string,
) {
  const timeOfDay = DateTime.fromFormat(timeOffset, "HH:mm");
  return DateTime.fromMillis(fromDateTime.toMillis())
    .plus({
      days: dayOffset,
    })
    .set({
      hour: timeOfDay.hour,
      minute: timeOfDay.minute,
    });
}
