import { MailingType } from "@/models/mailingType";
import {
  GridListAction,
  GridListColorCss,
} from "@/components/common/grid-list-with-actions/GridListWithActions.types";
import texts from "@/utils/texts";
import { RouteNamesMailings } from "@/router/routeNames";
import { MailingType as MailingTypeEnum } from "@/enums";
import settings from "@/store/context/settings.context";

export interface MailingTypeItem extends GridListAction {
  mailingType: MailingType;
}

export interface MailingTypeGroup {
  header?: string;
  items: MailingTypeItem[];
}

export interface MailingTypePickerProps {
  title?: string;
  description?: string;
  visible?: boolean;
  mailingTypeGroups: MailingTypeGroup[];
}

export const toMailingTypeGroups = (
  types: MailingType[] = settings.mailingTypes,
  routeQuery: {
    useDashboardSelection?: boolean;
    returnUrl?: string;
    activityId?: string;
  } = {
    useDashboardSelection: false,
    returnUrl: undefined,
    activityId: undefined,
  },
): MailingTypeGroup[] => {
  const items = types.map((type) => toMailingTypeItem(type, routeQuery));
  return groupItems(items);
};

const toMailingTypeItem = (
  type: MailingType,
  routeQuery: {
    useDashboardSelection?: boolean;
    returnUrl?: string;
    activityId?: string;
  } = {
    useDashboardSelection: false,
    returnUrl: undefined,
    activityId: undefined,
  },
): MailingTypeItem => {
  const baseItem = {
    title:
      texts.navigationItems.mailings.mailingTypePicker.mailingTypes[
        type.mailingType
      ].title,
    description:
      texts.navigationItems.mailings.mailingTypePicker.mailingTypes[
        type.mailingType
      ].description,
    mailingType: type,
    toRoute: {
      query: {
        useDashboardSelection: routeQuery.useDashboardSelection
          ? "true"
          : undefined,
        activityId: routeQuery.activityId,
        returnUrl: routeQuery.returnUrl,
      },
    },
  };

  switch (type.mailingType) {
    case MailingTypeEnum.General:
      return {
        ...baseItem,
        color: GridListColorCss.Indigo,
        icon: "newspaper",
        toRoute: {
          ...baseItem.toRoute,
          name: RouteNamesMailings.CREATE_GENERAL,
        },
      };
    case MailingTypeEnum.Activity:
      return {
        ...baseItem,
        color: GridListColorCss.Green,
        icon: "notifications",
        toRoute: {
          ...baseItem.toRoute,
          name: RouteNamesMailings.CREATE_INFORMATION,
        },
      };
    case MailingTypeEnum.ActivityReminder:
      return {
        ...baseItem,
        color: GridListColorCss.Blue,
        icon: "qr_code_2",
        toRoute: {
          ...baseItem.toRoute,
          name: RouteNamesMailings.CREATE_ACTIVITY_REMINDER,
        },
      };
    case MailingTypeEnum.ActivityInvite:
      return {
        ...baseItem,
        color: GridListColorCss.Purple,
        icon: "hotel_class",
        toRoute: {
          ...baseItem.toRoute,
          name: RouteNamesMailings.CREATE_ACTIVITY_INVITE,
        },
      };
    case MailingTypeEnum.ActivityVisitedSurvey:
      return {
        ...baseItem,
        color: GridListColorCss.Yellow,
        icon: "thumb_up",
        toRoute: {
          ...baseItem.toRoute,
          name: RouteNamesMailings.CREATE_ACTIVITY_VISITED_SURVEY,
        },
      };
    default:
      throw new Error(
        `Mapping mailing type ${type.mailingType} to MailingTypeItem has not been implemented yet.`,
      );
  }
};

const groupItems = (items: MailingTypeItem[]): MailingTypeGroup[] => {
  const mailingGroupTexts =
    texts.navigationItems.mailings.mailingTypePicker.mailingTypeGroups;
  const withActivity: MailingTypeGroup = {
    header: mailingGroupTexts.withActivity,
    items: [],
  };
  const withoutActivity: MailingTypeGroup = {
    header: mailingGroupTexts.withoutActivity,
    items: [],
  };

  items.forEach((item) => {
    if (
      [
        MailingTypeEnum.Activity,
        MailingTypeEnum.ActivityReminder,
        MailingTypeEnum.ActivityInvite,
        MailingTypeEnum.ActivityVisitedSurvey,
      ].includes(item.mailingType.mailingType)
    ) {
      withActivity.items.push(item);
    } else {
      withoutActivity.items.push(item);
    }
  });

  return [withActivity, withoutActivity].filter(
    (group) => group.items.length > 0,
  );
};
