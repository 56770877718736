<template>
  <ListItemBase
    :class="[
      'bg-white shadow',
      clickable ? 'transition-colors hover:bg-royal-50/20' : '',
      compact
        ? 'first:rounded-t-md first:pt-1 last:rounded-b-md last:pb-1'
        : 'rounded-md',
    ]"
    :title="title"
    :style="
      colorIndicator
        ? {
            'background-image': `linear-gradient(to right, ${colorIndicator} 4px, transparent 4px)`,
          }
        : {}
    "
  >
    <slot></slot>
  </ListItemBase>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { ListInjectionKeyCompact } from "./List.types";
import ListItemBase from "./ListItemBase.vue";

defineProps<{
  clickable?: boolean;
  title?: string;
  colorIndicator?: string;
}>();

const compact = inject<boolean>(ListInjectionKeyCompact, false);
</script>
