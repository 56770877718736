<template>
  <DropdownWrapper
    v-if="canViewPersonalData()"
    :origin="DropdownOrigin.TopRight"
  >
    <template v-slot:button>
      <ButtonGroup :color="Color.Gray" :size="ButtonSize.sm" flat round gap>
        <Button :data-testid="testIds.action.copyForm" @click="copyToClipboard">
          <IconAsync
            :state="state"
            icon="link"
            :color="Color.Gray"
            :size="IconSize.sm"
          />
          <span>
            {{ componentTexts.overview.copyLinkBtn }}
          </span>
        </Button>
        <ButtonGroupMenuButton />
      </ButtonGroup>
    </template>
    <template v-slot:items>
      <DropdownItem
        icon="download"
        :label="componentTexts.overview.downloadBtn"
        @click="downloadApplications"
      />
    </template>
  </DropdownWrapper>
  <Button
    v-else
    :data-testid="testIds.action.copyForm"
    :color="Color.Gray"
    round
    :size="ButtonSize.sm"
    @click="copyToClipboard"
  >
    <IconAsync
      :state="state"
      icon="link"
      :color="Color.Gray"
      :size="IconSize.sm"
    />
    <span>
      {{ componentTexts.overview.copyLinkBtn }}
    </span>
  </Button>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import { Color, FormType } from "@/enums";
import logger from "@/plugins/logger";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import { downloadFileBySimulatingClickOnTemporaryAnchorTag } from "@/utils/downloadFile";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { ref } from "vue";
import { DateTime } from "luxon";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";

const componentTexts = texts.navigationItems.applications;

// Open form
const getApplicationFormUrlAndCopy = async () => {
  const forms = await formsServiceClient.getForms(FormType.Application);
  const formUri = forms[0]?.uri;

  if (!formUri) {
    throw new Error(`Could not find brochure form uri!`);
  }

  const url = await formsServiceClient.getApplicationLink(formUri);
  await navigator.clipboard.writeText(url);
};

const { state, handler: copyToClipboard } = useAsyncState(
  getApplicationFormUrlAndCopy,
  componentTexts.copyToClipboard,
  undefined,
  true,
);

const downloadApplications = () => {
  const fromDate = ref<DateTime>(DateTime.now());
  const toDate = ref<DateTime>(DateTime.now());

  getRecruitmentYear()
    .then((recruitmentYear) => {
      const currentRecruitmentYearDates =
        recruitmentYear.toCurrentRecruitmentYearDates();

      fromDate.value = currentRecruitmentYearDates.start;
      toDate.value = currentRecruitmentYearDates.end;
    })
    .then(() => {
      eduConfigurationServiceClient
        .exportApplications(fromDate.value, toDate.value)
        .then((response) => {
          downloadFileBySimulatingClickOnTemporaryAnchorTag(
            response.data,
            response.fileName!,
          );
          Notify.success(componentTexts.download.success);
        });
    })
    .catch((error) => {
      Notify.failure(componentTexts.download.failure);
      logger.error(error);
    });
};
</script>
