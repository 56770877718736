<template>
  <div
    :class="[
      'flex items-center gap-2',
      disabled ? 'opacity-70' : 'opacity-100',
    ]"
  >
    <RadioBase :id v-model="modelValue" :value :disabled />
    <label v-if="label" :for="id" class="text-sm font-medium text-gray-700">{{
      label
    }}</label>
  </div>
</template>

<script setup lang="ts" generic="T extends string">
import RadioBase from "@/components/common/radiobutton/RadioBase.vue";

defineProps<{
  id?: string;
  value?: T;
  label?: string;
  disabled?: boolean;
}>();

const modelValue = defineModel<T>();
</script>
