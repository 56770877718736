import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { reactive } from "vue";
import { Permission } from "@/lib/eduConfigurationServiceClient";
import { hasPermissions } from "@/router/helpers/userCanGoTo";
import {
  ActivityDTO,
  ActivityLocationsOverviewDTO,
  AffiliateLinkOverviewItemDto,
  ActivityVisitedSurveyLinkOverviewDTO,
  RegistrationLinkWithRegistrationCountDTO,
} from "@/lib/eduConfigurationServiceClient";

export interface ActivityContext {
  activity: ActivityDTO | undefined;
  activityLocations: ActivityLocationsOverviewDTO | undefined;
  registrationLinks: RegistrationLinkWithRegistrationCountDTO[];
  affiliateLinks: AffiliateLinkOverviewItemDto[];
  surveyLinks: ActivityVisitedSurveyLinkOverviewDTO[];
}

const activityContext: ActivityContext = reactive<ActivityContext>({
  activity: undefined,
  activityLocations: undefined,
  registrationLinks: [],
  affiliateLinks: [],
  surveyLinks: [],
});

export default activityContext;

export function clearActivityContext() {
  activityContext.activity = undefined;
  activityContext.activityLocations = undefined;
  activityContext.registrationLinks = [];
  activityContext.affiliateLinks = [];
  activityContext.surveyLinks = [];
}

export async function loadActivityContext(id: string) {
  await Promise.all([
    loadActivity(id),
    loadActivityLocations(id),
    loadRegistrationLinks(id),
    loadAffiliateLinks(id),
    loadSurveyLinks(id),
  ]);
}

export async function loadActivity(id: string) {
  const canRead = hasPermissions([Permission.ActivitiesRead]);
  activityContext.activity = canRead
    ? await eduConfigurationServiceClient.getActivityById(id)
    : undefined;
}

export async function loadActivityLocations(id: string) {
  const canRead = hasPermissions([Permission.LocationsRead]);
  activityContext.activityLocations = canRead
    ? await eduConfigurationServiceClient.getActivityLocationsOverview(id)
    : undefined;
}

export async function loadRegistrationLinks(id: string) {
  const canRead = hasPermissions([Permission.RegistrationsRead]);
  activityContext.registrationLinks = canRead
    ? await eduConfigurationServiceClient.getAllRegistrationLinksWithRegistrationCounts(
        id,
      )
    : [];
}

export async function loadAffiliateLinks(id: string) {
  const canRead = hasPermissions([Permission.AffiliatesRead]);
  activityContext.affiliateLinks = canRead
    ? await eduConfigurationServiceClient.getAffiliateLinkOverview(id)
    : [];
}

export async function loadSurveyLinks(id: string) {
  const canRead = hasPermissions([Permission.SurveysRead]);
  activityContext.surveyLinks = canRead
    ? await eduConfigurationServiceClient.getSurveyLinkOverview(id)
    : [];
}
