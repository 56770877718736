import UnorderedListItem from "@/utils/quill/unordered-list";
import Quill, { QuillOptions } from "quill";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import DivBlock from "@/utils/quill/div-block";

Quill.register(DivBlock);

Quill.register(UnorderedListItem);

export default class QuillInstance {
  _instance?: Quill;

  get instance() {
    if (!this._instance) {
      throw new Error("Quill instance is not created");
    }
    return this._instance;
  }

  set instance(value: Quill) {
    this._instance = value;
  }

  public create(container: string | HTMLElement, options?: QuillOptions): void {
    this.instance = new Quill(container, options);
  }

  public getValue(): string {
    return this.instance.root.innerHTML;
  }

  public setValue(value: string): void {
    const delta = this.instance.clipboard.convert({ html: value });
    this.instance.setContents(delta);
  }
}
