<template>
  <RouterLinkAuth
    v-slot="{ href }"
    :to="{ name: RouteNames.ACTIVITY_EDIT, params: { id: activity.id } }"
    custom
  >
    <ListItem :colorIndicator="typeColor" clickable class="text-deepteal-700">
      <ListItemColumn :flex="8">
        <a class="flex w-full items-center justify-between" :href="href">
          <div
            class="flex flex-col items-stretch gap-1.5 truncate text-deepteal-500 md:gap-3"
          >
            <span class="flex items-center gap-1 text-xs font-medium">
              {{ currentDateIntervalString }}
            </span>
            <div class="flex items-start gap-1.5">
              <span
                class="max-w-xl truncate text-lg font-medium leading-tight"
                :title="activity.name"
              >
                {{ activity.name }}
              </span>
              <ActivityInProgress
                v-if="activity.status === ActivityStatus.inProgress"
              />
            </div>
          </div>
          <ActivityOnline v-if="activity.isOnline" />
        </a>
      </ListItemColumn>
      <ListItemDivider />

      <ListItemColumn hide>
        <a :href="href" class="flex w-full items-stretch justify-center">
          <CapacityIndicator
            v-if="activity.capacity"
            :count="activity.registrants"
            :capacity="activity.capacity"
            :accented="activity.registrants >= activity.capacity"
            :color="Color.Lavender"
            :accentedColor="Color.Lavender"
          />
          <span v-else class="text-base font-semibold text-lavender-600">{{
            activity.registrants
          }}</span>
        </a>
      </ListItemColumn>
      <ListItemDivider hide />
      <ListItemColumn hide class="flex justify-center">
        <span class="text-base font-semibold text-emerald-600">{{
          activity.visitors
        }}</span>
      </ListItemColumn>
      <ListItemDivider hide />
      <ListItemColumn :flex="0" class="self-center">
        <DropdownWrapper :origin="DropdownOrigin.TopRight">
          <template v-slot:button>
            <MenuButton :data-testid="testIds.actions">
              <Icon :size="IconSize.lg" icon="more_vert" :color="Color.Emerald">
              </Icon>
            </MenuButton>
          </template>
          <template v-slot:items>
            <DropdownItem @click="handleCopyClick">
              <div class="flex gap-5">
                <Icon icon="file_copy" :color="Color.DeepTeal"></Icon>
                <span class="text-deepteal-500">{{ texts.actions.copy }}</span>
              </div>
            </DropdownItem>
          </template>
        </DropdownWrapper>
      </ListItemColumn>
    </ListItem>
  </RouterLinkAuth>
</template>

<script setup lang="ts">
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import Activity, { ActivityStatus } from "@/models/activity";
import { RouteNames, RouteNamesActivityOverview } from "@/router/routeNames";
import texts from "@/utils/texts";
import { computed } from "vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import { testIds } from "@/utils/testing";
import { Color } from "@/enums";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { MenuButton } from "@headlessui/vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { useRouter } from "vue-router";
import logger from "@/plugins/logger";
import Notify from "@/utils/notify";
import { DateTime, Interval } from "luxon";
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { getGuidColor } from "@/utils/color";
import ActivityOnline from "@/views/organize/activities/components/ActivityOnline.vue";
import CapacityIndicator from "@/components/common/capacity-indicator/CapacityIndicator.vue";
import ListItemDivider from "@/components/common/list/ListItemDivider.vue";
import ActivityInProgress from "@/views/organize/activities/components/ActivityInProgress.vue";

const router = useRouter();
const props = defineProps<{
  activity: Activity;
}>();

const emit = defineEmits<{
  copy: [value: boolean];
}>();

const typeColor = computed(() => getGuidColor(props.activity.activityTypeId));

const currentDateIntervalString = computed(() =>
  Interval.fromDateTimes(
    props.activity.startDateTime,
    props.activity.endDateTime,
  ).toLocaleString(DateTime.DATE_FULL),
);

const handleCopyClick = () => {
  emit("copy", true);
  eduConfigurationServiceClient
    .copyActivity(props.activity.id)
    .then((response) => {
      Notify.success(texts.notifications.copy.success, [
        texts.models.activity.title,
      ]);

      router.push({
        name: RouteNamesActivityOverview.SETTINGS,
        params: { id: response },
      });
    })
    .catch((error) => {
      emit("copy", false);

      Notify.failure(texts.notifications.copy.failure, [
        texts.models.activity.title,
      ]);
      logger.log(error);
    });
};
</script>
