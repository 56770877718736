<template>
  <li class="mb-4 flex flex-col items-start gap-4">
    <div class="flex w-full items-center justify-between gap-2">
      <span class="text-base font-semibold">
        {{ configuration.name }}
      </span>
      <div v-if="configuration.id" class="flex gap-2">
        <DropdownWrapper
          v-if="canViewPersonalData()"
          :origin="DropdownOrigin.TopRight"
        >
          <template v-slot:button>
            <ButtonGroup
              :color="Color.Gray"
              :size="ButtonSize.sm"
              flat
              round
              gap
            >
              <Button
                :data-testid="testIds.action.copy"
                :disabled="configuration.formId === null"
                @click="copyLinkToClipboardClick"
              >
                <IconAsync
                  :state="state"
                  icon="link"
                  :color="Color.Gray"
                  :size="IconSize.sm"
                />
                <span>
                  {{ componentTexts.overview.copyLinkBtn }}
                </span>
              </Button>
              <ButtonGroupMenuButton />
            </ButtonGroup>
          </template>
          <template v-slot:items>
            <DropdownItem
              icon="download"
              :label="componentTexts.overview.exportRequestsBtn"
              :disabled="configuration.formId === null"
              @click="exportRegistrationsForConfigClick"
            />
          </template>
        </DropdownWrapper>
        <Button
          v-else
          :data-testid="testIds.action.copy"
          :disabled="configuration.formId === null"
          :color="Color.Gray"
          round
          :size="ButtonSize.sm"
          @click="copyLinkToClipboardClick"
        >
          <IconAsync
            :state="state"
            icon="link"
            :color="Color.Gray"
            :size="IconSize.sm"
          />
          <span>
            {{ componentTexts.overview.copyLinkBtn }}
          </span>
        </Button>
        <ButtonSettings
          v-if="canWriteTrialDays()"
          icon="settings"
          @click="emit('configuration:update')"
        >
          {{ texts.generic.settings }}
        </ButtonSettings>
      </div>
    </div>
    <List compact class="w-full">
      <template v-slot:header>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div></ListItemColumn>
        <ListItemColumn :flex="6">{{
          texts.models.studyProgram.name
        }}</ListItemColumn>
        <ListItemColumn hide :flex="0">
          {{ texts.navigationItems.dashboard.uniqueProspects }}
        </ListItemColumn>
        <ListItemColumn :flex="0"><div class="h-6 w-6"></div> </ListItemColumn>
      </template>
      <TrialDayItem
        v-for="item in items"
        :key="item.studyProgramId ?? item.configuration.id"
        :item="item"
        :color="color"
        :clickable="isClickable(item)"
        @click="handleItemClick(item)"
      />
    </List>
  </li>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import List from "@/components/common/list/List.vue";
import { Color, FormType } from "@/enums";
import { TrialDayConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { downloadExport } from "@/utils/downloadFile";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { TrialDayOverviewItem, canWriteTrialDays } from "../Overview.types";
import TrialDayItem from "./TrialDayItem.vue";
import { getRecruitmentYear } from "@/services/recruitmentYear.service";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";

const componentTexts = texts.navigationItems.organize.trialDays;

const props = defineProps<{
  configuration: TrialDayConfigurationDto;
  items: TrialDayOverviewItem[];
  color: Color;
}>();

const emit = defineEmits<{
  copy: [value: string];
  "configuration:update": [];
  "settings:update": [
    value: { configurationId: string; studyProgramId: string },
  ];
}>();

const isClickable = (item: TrialDayOverviewItem) =>
  canWriteTrialDays() && !item.isOrphaned;
const handleItemClick = (item: TrialDayOverviewItem) => {
  if (isClickable(item)) {
    emit("settings:update", {
      configurationId: item.configuration.id,
      studyProgramId: item.studyProgramId ?? "",
    });
  }
};

// Copy form link
const copyTrialDayFormLinkToClipboard = async () => {
  const forms = await formsServiceClient.getForms(
    FormType.TrialDayRegistration,
  );
  const formUri = forms.find((f) => f.id === props.configuration.formId)?.uri;

  if (!formUri) {
    throw new Error(`Could not find trial day form uri!`);
  }

  const url = await formsServiceClient.getTrialDayRegistrationLink(formUri);
  navigator.clipboard.writeText(url);
};

// Download registrations for configuration
const exportRegistrationsForConfigClick = async () =>
  downloadExport(state)(async () => {
    const recruitmentYear = await getRecruitmentYear();
    const currentRecruitmentYearDates =
      recruitmentYear.toCurrentRecruitmentYearDates();

    return await eduConfigurationServiceClient.exportTrialDaysRegistrationsForConfiguration(
      props.configuration.id,
      currentRecruitmentYearDates.start,
      currentRecruitmentYearDates.end,
    );
  }, texts.notifications.download);

const { state, handler: copyLinkToClipboardClick } = useAsyncState(
  copyTrialDayFormLinkToClipboard,
  componentTexts.overview.copyToClipboard,
  undefined,
  true,
);
</script>
