<template>
  <SlideOver
    v-model:visible="visible"
    :title="
      texts.navigationItems.organize.activity.affiliateLinks.update.slideOver
        .description
    "
  >
    <div class="flex flex-col gap-4 px-6">
      <SectionHeading
        :divider="false"
        :title="textsAffLinks.update.slideOver.formSection.title"
        :description="textsAffLinks.update.slideOver.formSection.description"
      />
      <AffiliateLinkEditActions
        v-if="activityId && overviewItem"
        :affLink="overviewItem"
        :activityId
      />
      <Divider />

      <SectionHeading
        :divider="false"
        :title="textsAffLinks.update.slideOver.detailsSection.title"
        :description="textsAffLinks.update.slideOver.detailsSection.description"
      />
      <DescriptionList v-if="overviewItem">
        <DescriptionListItem :name="texts.models.affiliate.name">
          <DescriptionListSingleValue :value="overviewItem?.affiliate.name" />
        </DescriptionListItem>
        <DescriptionListItem :name="texts.models.affiliate.emailAddress">
          <DescriptionListSingleValue
            :value="overviewItem?.affiliate.emailAddress"
          />
        </DescriptionListItem>
        <DescriptionListItem :name="texts.models.preEducationSchool.name">
          <DescriptionListSingleValue
            :value="affiliatePreEducationSchoolLabel"
          />
        </DescriptionListItem>
      </DescriptionList>
    </div>
  </SlideOver>
</template>

<script setup lang="ts">
import SlideOver from "@/components/common/slideover/SlideOver.vue";

import {
  IAffiliateLinkOverviewItemDto,
  IPreEducationSchool,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { computed } from "vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import AffiliateLinkEditActions from "@/views/organize/activities/activity/affiliate-links/AffiliateLinkEditActions.vue";
import Divider from "@/components/common/divider/Divider.vue";
import DescriptionList from "@/components/common/description-list/DescriptionList.vue";
import DescriptionListItem from "@/components/common/description-list/DescriptionListItem.vue";
import DescriptionListSingleValue from "@/components/common/description-list/DescriptionListSingleValue.vue";

const props = defineProps<{
  activityId: string;
  preEducationSchools: IPreEducationSchool[];
}>();

const overviewItem =
  defineModel<IAffiliateLinkOverviewItemDto>("affiliateLink");

const affiliatePreEducationSchoolLabel = computed(() => {
  const school = props.preEducationSchools.find(
    (s) => s.id === overviewItem.value?.affiliate.preEducationSchoolId,
  );
  if (!school) return "";
  return school.name + (school.city ? `, ${school.city}` : "");
});

const textsAffLinks = texts.navigationItems.organize.activity.affiliateLinks;

const visible = computed({
  get: () => !!overviewItem.value,
  set: (value: boolean) => {
    if (!value) {
      overviewItem.value = undefined;
    }
  },
});
</script>
