<template>
  <Filter v-model:expanded="expanded" :title="texts.models.activity.status">
    <FilterItem
      v-for="activityStatus in activityStatuses"
      :id="activityStatus.id"
      :key="activityStatus.id"
      v-model="checked"
      :name="activityStatus.name"
      :count="counts[activityStatus.id] || 0"
    ></FilterItem>
  </Filter>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { ref, watch } from "vue";

import FilterItem from "@/components/common/filter/FilterItem.vue";
import Filter from "@/components/common/filter/Filter.vue";
import { ActivityStatus } from "@/models/activity";

const props = defineProps<{
  reset: boolean;
  counts: Record<string, number>;
}>();

const emit = defineEmits<{
  (e: "filter", value: string[] | undefined): void;
}>();

const expanded = ref(true);
const checked = ref<string[]>([]);

const activityStatuses = [
  {
    id: ActivityStatus.inProgress,
    name: texts.enums.activityStatus[ActivityStatus.inProgress],
  },
  {
    id: ActivityStatus.scheduled,
    name: texts.enums.activityStatus[ActivityStatus.scheduled],
  },
  {
    id: ActivityStatus.finished,
    name: texts.enums.activityStatus[ActivityStatus.finished],
  },
];

watch(checked, (values) => {
  emit("filter", values.length > 0 ? values : undefined);
});

watch(
  () => props.reset,
  () => (checked.value = []),
);
</script>
