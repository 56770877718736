<template>
  <template
    v-if="
      showStudyProgramExplainer &&
      filters.studyProgramFilter.studyProgramIds &&
      filters.studyProgramFilter.studyProgramIds.length > 0
    "
  >
    <p>
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span
      >{{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.showedInterestIn}`
      }}
      <span
        v-for="item in studyProgramLabels"
        :key="item"
        class="ml-2 rounded bg-gray-100 p-1"
      >
        {{ item }}
      </span>
    </p>
  </template>
  <template v-if="showDateRangeExplainer && filters.dateRange">
    <p>
      <span class="font-bold">{{ texts.generic.and.toUpperCase() }}</span
      >{{
        ` ${texts.navigationItems.mailing.steps.selectionDefinition.inPeriod}`
      }}
      <span class="rounded bg-gray-100 p-1">
        {{ filters.dateRange.from.toLocaleString(DateTime.DATE_SHORT) }} /
        {{ filters.dateRange.to.toLocaleString(DateTime.DATE_SHORT) }}
      </span>
    </p>
  </template>
</template>

<script setup lang="ts">
import {
  ISelectionFiltersDto,
  SelectionCriterionDtoType,
} from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";
import { DateTime } from "luxon";
import { computed } from "vue";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import settings from "@/store/context/settings.context";
import {
  doesCriterionSupportFilter,
  SelectionFilterType,
} from "@/metadata/selectionCriteria";

const props = defineProps<{
  filters: ISelectionFiltersDto;
  criterionType: SelectionCriterionDtoType;
  context: CriterionFieldContext;
}>();

const showStudyProgramExplainer = computed(() =>
  doesCriterionSupportFilter(
    props.criterionType,
    SelectionFilterType.StudyProgram,
  ),
);

const showDateRangeExplainer = computed(() =>
  doesCriterionSupportFilter(
    props.criterionType,
    SelectionFilterType.DateRange,
  ),
);

const studyProgramLabels = computed(() =>
  props.filters.studyProgramFilter.studyProgramIds.map(
    (id) => settings.allStudyPrograms.find((sp) => sp.id === id)?.displayName,
  ),
);
</script>
