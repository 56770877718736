<template>
  <div class="flex h-full flex-col items-center">
    <div
      class="flex w-full max-w-xl flex-1 flex-col justify-end gap-4 pb-12 text-center"
    >
      <Icon icon="person_search" class="text-9xl text-lavender-500" />
      <span class="text-4xl font-light text-deepteal-500">
        {{ componentTexts.title }}
      </span>
      <span class="text-base font-light text-deepteal-700">{{
        componentTexts.description
      }}</span>
    </div>
    <div class="w-full max-w-xl flex-grow">
      <div class="flex w-full items-center gap-2">
        <Search
          v-model="query"
          class="flex-grow"
          :placeholder="componentTexts.query.placeholder"
          :debounceMs="0"
          @keyup.enter="emit('submit', query)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/common/icon/Icon.vue";
import Search from "@/components/common/search/Search.vue";
import texts from "@/utils/texts";
import { ref } from "vue";

defineProps<{
  loading?: boolean;
}>();

const emit = defineEmits<{
  submit: [value: string | undefined];
}>();
const componentTexts = texts.navigationItems.contacts;

const query = ref<string>();
</script>
