<template>
  <Dropdown
    :label="texts.actions.actions"
    :origin="DropdownOrigin.TopRight"
    :testId="testIds.actions"
  >
    <DropdownItem
      v-if="mailing.status === MailingStatus.Concept"
      :data-testid="testIds.action.edit"
      :label="texts.navigationItems.mailing.actions.editConcept"
      @click="emit('editConcept')"
    ></DropdownItem>
    <DropdownItem
      v-if="mailing.status === MailingStatus.Planned"
      data-testid="set-planned-mailing-back-to-concept-and-edit"
      :label="texts.navigationItems.mailing.actions.backToConceptAndEdit"
      @click="setBackToConcept(true)"
    ></DropdownItem>
    <DropdownItem
      v-if="mailing.status === MailingStatus.Planned"
      data-testid="set-planned-mailing-back-to-concept"
      :label="texts.navigationItems.mailing.actions.backToConcept"
      @click="setBackToConcept(false)"
    ></DropdownItem>
    <DropdownItem
      :data-testid="testIds.action.copy"
      :label="texts.navigationItems.mailing.actions.copy"
      @click="triggerCopy"
    ></DropdownItem>
    <DropdownItem
      v-if="
        mailing.status === MailingStatus.Concept ||
        mailing.status === MailingStatus.Failed
      "
      data-testid="delete-mailing"
      :label="texts.actions.delete"
      @click="deleteModalVisible = true"
    ></DropdownItem>
  </Dropdown>
  <!-- Confirm delete modal -->
  <Modal
    v-model:visible="deleteModalVisible"
    :type="ModalType.danger"
    :description="texts.actions.confirmDelete.text"
    :title="texts.actions.confirmDelete.action"
  >
    <template v-slot:buttons>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          data-testid="confirm-delete"
          class="sm:ml-3"
          :color="Color.Alert"
          :label="texts.actions.delete"
          :disabled="!deleteModalVisible"
          :loading="deletingMail"
          @click="deleteAction"
        />
        <Button
          data-testid="cancel-delete"
          class="mt-3 sm:mt-0"
          :label="texts.actions.cancel"
          :disabled="deletingMail || !deleteModalVisible"
          @click="deleteModalVisible = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Button from "@/components/common/button/Button.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import Dropdown from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Modal from "@/components/common/modal/Modal.vue";
import { Color, MailingStatus } from "@/enums";
import { MailingDTO } from "@/lib/eduConfigurationServiceClient";
import {
  cancelMailing,
  copyMailing,
  deleteMailing,
} from "@/services/mailings.service";
import Notify from "@/utils/notify";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { ref } from "vue";

const props = defineProps<{
  mailing: MailingDTO;
}>();

const emit = defineEmits<{
  editConcept: [];
  "delete:succeeded": [];
  "backToConcept:succeeded": [];
  "backToConceptAndEdit:succeeded": [];
  "copy:succeeded": [id: string];
}>();

const setBackToConcept = (edit: boolean) => {
  return cancelMailing(props.mailing.id)
    .then(() => {
      if (edit) {
        emit("editConcept");
      } else {
        emit("backToConcept:succeeded");
      }
    })
    .then(() => {
      Notify.success(texts.navigationItems.mailing.cancel.success);
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.cancel.failure);
      throw error;
    });
};

// Copy mail
const triggerCopy = () => {
  copyMailing(props.mailing.id)
    .then((id) => {
      Notify.success(texts.navigationItems.mailing.copy.success);

      emit("copy:succeeded", id);
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.copy.failure);
      throw error;
    });
};

// Delete mail
const deleteModalVisible = ref<boolean>(false);
const deletingMail = ref<boolean>(false);

const deleteAction = () => {
  deletingMail.value = true;
  return deleteMailing(props.mailing.id)
    .then(() => {
      emit("delete:succeeded");
      Notify.success(texts.navigationItems.mailing.delete.success);
    })
    .catch((error) => {
      Notify.failure(texts.navigationItems.mailing.delete.failure);
      throw error;
    })
    .finally(() => {
      deletingMail.value = false;
      deleteModalVisible.value = false;
    });
};
</script>
