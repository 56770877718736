<template>
  <SlideOver
    v-model:visible="visibleInternal"
    :title="texts.models.question.newQuestion"
  >
    <FormTemplateQuestionSettingsForm
      :currentLocale="currentLocale"
      :formValues="{ isRequired: true, isInline: true }"
      @submit="submitCreate"
    >
      <ButtonSubmit :loading="actionLoading" />
      <ButtonCancel @click="cancelUpdate" />
    </FormTemplateQuestionSettingsForm>
  </SlideOver>
</template>

<script setup lang="ts">
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import SlideOver from "@/components/common/slideover/SlideOver.vue";
import { Culture } from "@/enums";
import FormTemplateQuestionSettingsForm from "@/views/settings/forms/components/form-template-editor/FormTemplateQuestionSettingsForm.vue";
import { computed, ref } from "vue";
import { QuestionSettingsFormValues } from "./FormTemplateQuestionSettingsForm.types";
import { SelectOption } from "@/components/common/select/SelectOption";
import texts from "@/utils/texts";

const props = defineProps<{
  visible: boolean;
  currentLocale: Culture;
  questionOptions: SelectOption[];
}>();

const emit = defineEmits<{
  "update:visible": [boolean];
  "update:succeeded": [boolean];
  "update:cancel": [];
  "create:question": [question: QuestionSettingsFormValues];
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const submitCreate = (values: QuestionSettingsFormValues) => {
  emit("create:question", values);
  visibleInternal.value = false;
};

const cancelUpdate = () => {
  visibleInternal.value = false;
  emit("update:cancel");
};

const actionLoading = ref<boolean>(false);
</script>
