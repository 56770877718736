<template>
  <FormLayout @submit="onSubmit">
    <FormFieldSelect
      id="id"
      class="w-full flex-1"
      :items="
        questionOptions.filter(
          (questionOption) =>
            form.values.id === questionOption.value ||
            !editContext.formQuestions.some(
              (q) => q.id === questionOption.value,
            ),
        )
      "
    />
    <div v-if="questionRef.answers.length > 0">
      <h2 class="py-2 font-semibold text-deepteal-500">
        {{ componentTexts.question.answers }}
      </h2>

      <div class="mb-8">
        <template v-for="answer in questionRef.answers" :key="answer.id">
          <div class="flex w-full items-center gap-4 py-1 text-sm">
            <Checkbox :value="answer.id" :disabled="true" />
            <span>{{ answer.getLocalizedLabel(currentLocale) }}</span>
            <Badge
              v-if="getNumberOfConditionalQuestions(answer.id!) > 0"
              :label="getNumberOfConditionalQuestions(answer.id!).toString()"
              :type="BadgeType.info"
            />
          </div>
        </template>
      </div>

      <h2 class="py-2 font-semibold text-deepteal-500">
        {{ componentTexts.question.conditionalQuestions }}
      </h2>
      <template v-for="(field, index) in fields" :key="field.value.questionId">
        <div class="my-2 flex w-full items-center gap-4 text-sm text-gray-400">
          <span>{{ componentTexts.question.opensIf }}</span>
          <FormFieldSelect
            :id="`${conditionalsFieldId}[${index}].answerOptionId`"
            class="w-1/2 flex-initial"
            :items="answerOptions"
            allowUnset
          />
          <span>{{ componentTexts.question.isChecked }}</span>
        </div>
        <div class="my-2 flex w-full items-center gap-4">
          <FormFieldSelect
            :id="`${conditionalsFieldId}[${index}].questionId`"
            class="w-full flex-1"
            :items="
              questionOptions.filter(
                (questionOption) =>
                  questionOption.value ===
                    form.values.conditionalQuestions[index].questionId ||
                  !form.values.conditionalQuestions.some(
                    (q) => q.questionId === questionOption.value,
                  ) ||
                  !editContext.formQuestions.some(
                    (q) => q.id === questionOption.value,
                  ),
              )
            "
          />
          <button
            type="button"
            :disabled="field.isFirst"
            @click="swap(index, index - 1)"
          >
            <Icon
              icon="arrow_upward"
              :size="IconSize.xs"
              :color="Color.Black"
              class="transition-all hover:stroke-info-500"
            />
          </button>
          <button
            type="button"
            :disabled="field.isLast"
            @click="swap(index, index + 1)"
          >
            <Icon
              icon="arrow_downward"
              :size="IconSize.xs"
              :color="Color.Black"
              class="transition-all hover:stroke-info-500"
            />
          </button>
          <button type="button" @click="remove(index)">
            <Icon
              icon="delete"
              :size="IconSize.xs"
              :color="Color.Alert"
              class="transition-all hover:stroke-alert-500"
            />
          </button>
        </div>
        <div class="mb-8 mt-2 flex w-full items-center gap-4 text-sm">
          <span>{{ componentTexts.question.isRequired }}</span>
          <FormFieldToggle
            :id="`${conditionalsFieldId}[${index}].isRequired`"
          />
          <span>{{ componentTexts.question.isInline }}</span>
          <FormFieldToggle :id="`${conditionalsFieldId}[${index}].isInline`" />
        </div>
      </template>
      <ButtonSettings
        icon="add"
        @click="push({ questionId: '', answerOptionId: '' })"
      >
        {{
          interpolate(
            texts.actions.addNew,
            componentTexts.question.conditionalQuestion,
          )
        }}
      </ButtonSettings>
    </div>
    <span class="font-semibold">{{ texts.models.question.otherSettings }}</span>
    <div class="flex w-full flex-col gap-4 text-sm">
      <FormFieldCheckbox
        :id="fieldIds.isRequired"
        :label="componentTexts.question.isRequired"
      />
      <FormFieldCheckbox
        :id="fieldIds.isInline"
        :label="componentTexts.question.isInline"
      />
    </div>
    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import { interpolate } from "@/dictionary";
import { Color, Culture, FormType } from "@/enums";
import { fields as formFields } from "@/utils/miscellaneous";
import texts from "@/utils/texts";
import { useFieldArray, useForm } from "vee-validate";
import * as yup from "yup";
import {
  ConditionalQuestionFormValues,
  QuestionSettingsFormValues,
} from "./FormTemplateQuestionSettingsForm.types";
import { computed, inject } from "vue";
import {
  FormTemplateEditContext,
  FormTemplateEditContextKey,
} from "../../FormExtensions";
import Icon from "@/components/common/icon/Icon.vue";
import FormLayout from "@/components/common/form/FormLayout.vue";
import FormFieldCheckbox from "@/components/common/checkbox/FormFieldCheckbox.vue";
import { ref } from "vue";
import { FormQuestionDTO } from "@/lib/formsServiceClient";
import { watch } from "vue";

const componentTexts = texts.navigationItems.manage.settings.formTemplates;

const props = defineProps<{
  formValues?: Partial<QuestionSettingsFormValues>;
  currentLocale: Culture;
  question?: FormQuestionDTO;
}>();

const emit = defineEmits<{
  submit: [QuestionSettingsFormValues];
}>();

const editContext = inject(
  FormTemplateEditContextKey,
) as FormTemplateEditContext;
if (!editContext) {
  throw new Error("FormEditorContext not found");
}

const form = useForm<QuestionSettingsFormValues>({
  initialValues: props.formValues,
  validationSchema: yup.object({
    id: yup.string().required(),
    isInline: yup.boolean(),
    isRequired: yup.boolean(),
    conditionalQuestions: yup.array().of(
      yup.object().shape({
        questionId: yup.string().required(),
        answerOptionId: yup.string().required(),
        isInline: yup.boolean(),
        isRequired: yup.boolean(),
      }),
    ),
  }),
});

const fieldIds = formFields<QuestionSettingsFormValues>();
const conditionalsFieldId = "conditionalQuestions";
const { swap, remove, push, fields } =
  useFieldArray<ConditionalQuestionFormValues>(conditionalsFieldId);

const onSubmit = form.handleSubmit((values) => {
  emit("submit", values);
});

const getNumberOfConditionalQuestions = (answerOptionId: string) => {
  return (
    props.formValues?.conditionalQuestions?.filter(
      (question) => question.answerOptionId === answerOptionId,
    ).length ?? 0
  );
};

const questionRef = ref(new FormQuestionDTO(props.question));

const answerOptions = computed<SelectOption[]>(() =>
  questionRef.value.answers.map((answer) => ({
    label: answer.getLocalizedLabel(props.currentLocale),
    value: answer.id,
  })),
);

const questionOptions = computed<SelectOption[]>(() => {
  return (
    editContext?.questions
      .filter((q) =>
        editContext.formType === FormType.ActivityVisitedSurvey
          ? q.isSurveyQuestion
          : !q.isSurveyQuestion,
      )
      .map((q) => {
        return {
          value: q.id,
          label:
            q.localizations.find((l) => l.locale.value === props.currentLocale)
              ?.label ?? "",
        } satisfies SelectOption;
      })
      .toSorted((a, b) => a.label.localeCompare(b.label)) ?? []
  );
});

watch(
  () => form.values.id,
  (value) => {
    if (value) {
      const question = editContext?.questions.find((q) => q.id === value);
      if (!question) throw new Error("Question not found");
      questionRef.value.setQuestionId(value);
      questionRef.value.setQuestionLocalizations(
        question.localizations.map((l) => l.mapToDto()) ?? [],
      );
      questionRef.value.setQuestionAnswers(
        question.answerOptions?.map((l) => l.mapToDto()) ?? [],
      );
    }
  },
  { immediate: true },
);
</script>
