<template>
  <DropdownWrapper :origin="DropdownOrigin.TopRight">
    <template v-slot:button>
      <ButtonGroup :color="Color.Gray" :size="ButtonSize.sm" flat round gap>
        <Button
          :data-testid="testIds.action.copyForm"
          :disabled="!configuration.formId"
          @click="copyToClipboard"
        >
          <IconAsync
            :state="state"
            icon="link"
            :color="Color.Gray"
            :size="IconSize.sm"
          ></IconAsync>
          <span>
            {{ texts.navigationItems.brochures.overview.copyFormLink }}
          </span>
        </Button>
        <ButtonGroupMenuButton />
      </ButtonGroup>
    </template>
    <template v-slot:items>
      <DropdownItem
        icon="link"
        :disabled="!configuration.formId"
        :label="texts.navigationItems.brochures.overview.copyFormLink"
        @click="copyToClipboard"
      ></DropdownItem>
      <DropdownItem
        v-if="canViewPersonalData()"
        icon="download"
        :label="texts.navigationItems.brochures.overview.downloadRequestsBtn"
        @click="downloadRequests"
      ></DropdownItem>
    </template>
  </DropdownWrapper>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import Button from "@/components/common/button/Button.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import { Color, FormType } from "@/enums";
import { BrochureConfigurationDto } from "@/lib/eduConfigurationServiceClient";
import { canViewPersonalData } from "@/router/helpers/userCanGoTo";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import { downloadExport } from "@/utils/downloadFile";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";

const props = defineProps<{
  configuration: BrochureConfigurationDto;
}>();

// Open form
const getBrochureFormUrlAndCopy = async () => {
  const formType = props.configuration.isHardCopy
    ? FormType.HardCopyBrochureRequest
    : FormType.DigitalBrochureRequest;
  const forms = await formsServiceClient.getForms(formType);
  const formUri = forms.find((f) => f.id === props.configuration.formId)?.uri;

  if (!formUri) {
    throw new Error(`Could not find brochure form uri!`);
  }

  const url = await formsServiceClient.getBrochureRequestLink(formUri);
  await navigator.clipboard.writeText(url);
};

const downloadRequests = async () =>
  downloadExport(state)(
    () =>
      eduConfigurationServiceClient.exportBrochureRequestsPerConfiguration(
        props.configuration.id,
      ),
    texts.navigationItems.brochures.downloadRequests,
  );

const { state, handler: copyToClipboard } = useAsyncState(
  getBrochureFormUrlAndCopy,
  texts.navigationItems.brochures.copyToClipboard,
  undefined,
  true,
);
</script>
