<template>
  <Alert v-if="calculatedSendDateTime" :color="Color.Info">
    {{ texts.models.mailing.timingRelativeCalculation }}:
    {{ calculatedSendDateTime }}
  </Alert>
</template>

<script setup lang="ts">
import Alert from "@/components/common/alert/Alert.vue";
import { Color } from "@/enums";
import texts from "@/utils/texts";
import { calculateSendDateTime } from "@/views/mailings/mailing/components/MailingCalculatedSendDateTime.types";
import { DateTime } from "luxon";
import { computed } from "vue";
const props = defineProps<{
  fromDateTime: DateTime;
  dayOffset: number;
  timeOffset: string;
}>();

const calculatedSendDateTime = computed(() =>
  calculateSendDateTime(
    props.fromDateTime,
    props.dayOffset,
    props.timeOffset,
  ).toLocaleString(DateTime.DATETIME_MED),
);
</script>
