<template>
  <Loader v-if="loading" />
  <div v-else class="flex h-full w-full flex-col gap-8">
    <AffiliateLinksHeader>
      <div class="flex gap-4">
        <DropdownWrapper :origin="DropdownOrigin.TopRight">
          <template v-slot:button>
            <ButtonGroup
              :color="Color.Gray"
              :size="ButtonSize.sm"
              flat
              round
              gap
            >
              <Button @click="downloadAllRegistrations">
                <IconAsync
                  :state="state"
                  icon="download"
                  :color="Color.Gray"
                  :size="IconSize.sm"
                />
                <span>{{ componentTexts.downloadAllRegistrations.title }}</span>
              </Button>
              <ButtonGroupMenuButton />
            </ButtonGroup>
          </template>
          <template v-slot:items>
            <DropdownItem
              icon="download"
              :label="componentTexts.downloadOverview.title"
              @click="downloadAllAffiliateLinks"
            />
          </template>
        </DropdownWrapper>
        <ButtonAdd @click="isCreateOpen = true"> </ButtonAdd>
      </div>
    </AffiliateLinksHeader>

    <AffiliateLinksList
      v-if="affiliateLinks"
      :affiliateLinks="affiliateLinks"
      :preEducationSchools="preEducationSchools"
      :activityId="props.id"
      @click="
        currentlyEditing = affiliateLinks?.find(
          (al) => al.affiliateId === $event.affiliateId,
        )
      "
    />

    <CreateAffiliateLink
      v-model:visible="isCreateOpen"
      :affiliates="createUpdateEligibleAffiliates"
      :registrationLinks
      :preEducationSchools
      @stale="reloadAffiliateLinks"
    />

    <AffiliateLinkDetails
      v-model:affiliateLink="currentlyEditing"
      :activityId="props.id"
      :preEducationSchools="preEducationSchools"
    />
  </div>
</template>
<script setup lang="ts">
import activityContext, {
  loadAffiliateLinks,
} from "@/store/context/activity.context";
import { computed, ref } from "vue";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ButtonSize } from "@/components/common/button/Button.types";
import ButtonGroupMenuButton from "@/components/common/button/ButtonGroupMenuButton.vue";
import ButtonGroup from "@/components/common/button/ButtonGroup.vue";
import Button from "@/components/common/button/Button.vue";
import { DropdownOrigin } from "@/components/common/dropdown/Dropdown.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import DropdownWrapper from "@/components/common/dropdown/DropdownWrapper.vue";
import IconAsync from "@/components/common/icon/IconAsync.vue";
import { IconSize } from "@/components/common/icon/Icon.types";
import { Color } from "@/enums";
import AffiliateLinksHeader from "@/views/organize/activities/activity/affiliate-links/AffiliateLinksHeader.vue";
import CreateAffiliateLink from "@/views/organize/activities/activity/affiliate-links/CreateAffiliateLink.vue";
import {
  IAffiliateDto,
  IAffiliateLinkOverviewItemDto,
  IPreEducationSchool,
} from "@/lib/eduConfigurationServiceClient";
import { ActivityRouteProps } from "@/router/guards/activityContextGuard";
import Loader from "@/components/common/loader/Loader.vue";
import AffiliateLinksList from "@/views/organize/activities/activity/affiliate-links/AffiliateLinksList.vue";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import AffiliateLinkDetails from "@/views/organize/activities/activity/affiliate-links/AffiliateLinkDetails.vue";
import { useFileDownloader } from "@/utils/downloadFile";
import texts from "@/utils/texts";

const props = defineProps<ActivityRouteProps>();

const allAffiliates = ref<IAffiliateDto[]>([]);
const unusedAffliates = computed<IAffiliateDto[]>(() =>
  allAffiliates.value.filter(
    (a) => !props.affiliateLinks.map((al) => al.affiliateId).includes(a.id),
  ),
);

const componentTexts = texts.navigationItems.organize.activity.affiliateLinks;

const preEducationSchools = ref<IPreEducationSchool[]>([]);
const registrationLinks = ref(activityContext.registrationLinks);
const currentlyEditing = ref<IAffiliateLinkOverviewItemDto>();
const isCreateOpen = ref(false);
const loading = ref(true);

const currentlyEditingAffiliate = computed<IAffiliateDto | undefined>(() =>
  currentlyEditing.value
    ? allAffiliates.value.find(
        (a) => a.id === currentlyEditing.value?.affiliateId,
      )
    : undefined,
);

const createUpdateEligibleAffiliates = computed(() => {
  // If we are currently editing an affiliate, we want to include it in the list
  //  because we need the form to be able to populate its fields with the current values
  return [
    ...unusedAffliates.value,
    ...(currentlyEditingAffiliate.value
      ? [currentlyEditingAffiliate.value]
      : []),
  ];
});

const fetchMetaData = async () => {
  loading.value = true;

  preEducationSchools.value =
    await eduConfigurationServiceClient.getAllPreEducationSchools();

  allAffiliates.value = await eduConfigurationServiceClient.getAllAffiliates();

  loading.value = false;
};

const reloadAffiliateLinks = async () => {
  await loadAffiliateLinks(props.id);
};

const { state, downloadExport } = useFileDownloader();

const downloadAllRegistrations = async () =>
  downloadExport(
    () =>
      eduConfigurationServiceClient.exportRegistrationsForAllAffiliates(
        props.id,
      ),
    componentTexts.downloadAllRegistrations,
  );

const downloadAllAffiliateLinks = async () =>
  downloadExport(
    () => eduConfigurationServiceClient.exportAffiliateLinks(props.id),
    componentTexts.downloadOverview,
  );

fetchMetaData();
</script>
