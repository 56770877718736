<template>
  <div class="flex w-full flex-wrap items-stretch gap-1 sm:gap-x-4">
    <div class="flex flex-1 items-center justify-center gap-1 text-sm">
      <span
        :class="[
          'text-base',
          accented ? 'font-bold' : 'font-semibold',
          accented ? getTextColor(accentedColor) : getTextColor(color),
        ]"
        >{{ count }}</span
      >
      <span v-if="capacity" class="text-sm font-semibold text-gray-300">/</span>
      <span
        v-if="capacity"
        :class="[
          accented ? getTextColor(accentedColor) : getTextColor(color),
          'text-sm font-normal opacity-80',
        ]"
        >{{ capacity }}</span
      >
    </div>
    <div
      :class="[
        'relative h-1 w-full rounded',

        capacity ? 'opacity-100' : 'opacity-0',
      ]"
    >
      <div
        :class="[
          'h-full rounded opacity-20',
          accented ? getBgColor(accentedColor) : getBgColor(color),
        ]"
      ></div>
      <div
        :class="[
          'absolute left-0 top-0 h-full rounded',
          accented ? getBgColor(accentedColor) : getBgColor(color),
        ]"
        :style="{
          width: `${(percentage > 1 ? 1 : percentage) * 100}%`,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Color } from "@/enums";
import { getBgColor, getTextColor } from "@/utils/color";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    count: number;
    capacity?: number;
    accented?: boolean;
    color?: Color;
    accentedColor?: Color;
  }>(),
  {
    capacity: undefined,
    color: Color.Lavender,
    accentedColor: Color.Emerald,
  },
);

const percentage = computed(() =>
  props.capacity ? props.count / props.capacity : 0,
);
</script>
