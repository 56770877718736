<template>
  <RouterLinkAuth
    v-slot="{ navigate }"
    :to="{
      name: RouteNamesActivityOverview.REGISTRATIONLINKS_EDIT,
      params: { linkId: props.regLink.id },
    }"
    custom
  >
    <ListItem
      :title="regLink.name"
      clickable
      :data-testid="testIds.registrationLinks.item"
    >
      <ListItemColumn :flex="2" @click="navigate">
        <div class="flex flex-col gap-1 truncate text-deepteal-600">
          <span class="truncate text-base font-semibold">
            {{ regLink.name }}
          </span>
          <span class="flex flex-wrap items-center gap-1 text-xs text-gray-500">
            <span class="truncate">
              {{ texts.models.formTemplate.title }}: {{ regLinkForm?.name }}
            </span>
            <span>-</span>
            <span class="truncate">
              {{ texts.models.emailTemplate.title }}:
              {{ regLink.confirmationEmailTemplateName }}
            </span>
          </span>
        </div>
      </ListItemColumn>
      <ListItemColumn :flex="0">
        <ListItemActions>
          <DropdownItem @click="copyToClipboard">
            <Icon
              icon="link"
              :state="state"
              :color="Color.Black"
              :size="IconSize.sm"
              :stroke="IconStroke.md"
            />
            <span class="text-deepteal-500">
              {{ texts.actions.copyLink }}
            </span>
          </DropdownItem>
          <slot></slot>
        </ListItemActions>
      </ListItemColumn>
    </ListItem>
  </RouterLinkAuth>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { IconSize, IconStroke } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { Color } from "@/enums";
import { RegistrationLinkWithRegistrationCountDTO } from "@/lib/eduConfigurationServiceClient";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import { formsServiceClient } from "@/services/formsService.client.service";
import settings from "@/store/context/settings.context";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";
import { computed } from "vue";

const props = defineProps<{
  regLink: RegistrationLinkWithRegistrationCountDTO;
}>();

const regLinkForm = computed(() =>
  settings.forms?.find((form) => props.regLink.formId === form.id),
);

const createCopyToClipboardUrl = async () => {
  if (!regLinkForm.value) throw new Error("Could not find form");

  const url = await formsServiceClient.getRegistrationLink(
    regLinkForm.value.uri,
    props.regLink.id,
  );

  if (!url) {
    throw new Error(
      `Could not get registration link for ${props.regLink.name}`,
    );
  }

  await navigator.clipboard.writeText(url);
};

const { state, handler: copyToClipboard } = useAsyncState(
  createCopyToClipboardUrl,
  texts.notifications.copyToClipboard,
  undefined,
  true,
);
</script>
