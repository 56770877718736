<template>
  <RouterLinkAuth
    v-slot="{ navigate }"
    :to="{
      name: RouteNamesActivityOverview.SURVEY_LINKS_EDIT,
      params: { linkId: props.surveyLink.id },
    }"
    custom
  >
    <ListItem
      :title="surveyLink.name"
      clickable
      :data-testid="testIds.surveyLinks.item"
    >
      <ListItemColumn :flex="2" @click="navigate">
        <div class="flex flex-col gap-1 truncate text-deepteal-600">
          <span class="truncate text-base font-semibold">
            {{ surveyLink.name }}
          </span>
        </div>
      </ListItemColumn>
      <ListItemColumn :flex="0">
        <ListItemActions>
          <DropdownItem @click="copyToClipboard">
            <Icon
              icon="link"
              :state="state"
              :color="Color.Black"
              :size="IconSize.sm"
              :stroke="IconStroke.md"
            />
            <span class="text-deepteal-500">
              {{ texts.actions.copyLink }}
            </span>
          </DropdownItem>
          <slot></slot>
        </ListItemActions>
      </ListItemColumn>
    </ListItem>
  </RouterLinkAuth>
</template>

<script setup lang="ts">
import { useAsyncState } from "@/components/common/async/Async.types";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import { IconSize, IconStroke } from "@/components/common/icon/Icon.types";
import Icon from "@/components/common/icon/Icon.vue";
import ListItem from "@/components/common/list/ListItem.vue";
import ListItemActions from "@/components/common/list/ListItemActions.vue";
import ListItemColumn from "@/components/common/list/ListItemColumn.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { Color } from "@/enums";
import { ActivityVisitedSurveyLinkOverviewDTO } from "@/lib/eduConfigurationServiceClient";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import { testIds } from "@/utils/testing";
import texts from "@/utils/texts";

const props = defineProps<{
  surveyLink: ActivityVisitedSurveyLinkOverviewDTO;
}>();

const createCopyToClipboardUrl = async () => {
  await navigator.clipboard.writeText(props.surveyLink.uri);
};

const { state, handler: copyToClipboard } = useAsyncState(
  createCopyToClipboardUrl,
  texts.notifications.copyToClipboard,
  undefined,
  true,
);
</script>
