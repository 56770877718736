<template>
  <div class="flex flex-col">
    <div
      ref="element"
      :class="[
        'flex flex-1 flex-col bg-white ring-emerald-500 ring-offset-2 focus-within:ring-2 hover:ring-2 hover:ring-opacity-50',
        {
          'p-3': !inline,
          'rounded py-3': inline,
        },
      ]"
    ></div>
  </div>
</template>

<script setup lang="ts">
import QuillInstance from "@/utils/quil-instance";
import UnorderedListItem from "@/utils/quill/unordered-list";
import { computed, onMounted, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    placeholder?: string;
    disabled?: boolean;
    inline?: boolean;
    inlineCss?: {
      smtFontSize?: string;
      smtFontText?: string;
      smtColorText?: string;
      smtFontHeader?: string;
      smtColorHeader?: string;
      smtColorLink?: string;
      smtLinkTextDecoration?: string;
      smtLinkFontWeight?: string;
    };
  }>(),
  {
    modelValue: undefined,
    inlineCss: () => ({}),
    placeholder: undefined,
    disabled: false,
    inline: false,
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: string | undefined];
}>();

const element = ref<HTMLElement | null>(null);

const modelValueInternal = computed({
  get: () => {
    return props.modelValue;
  },
  set: (value) => {
    emit("update:modelValue", value);
  },
});

const quill = new QuillInstance();

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ header: 1 }, { header: 2 }, { header: 3 }],
  ["link"],
  [{ list: "ordered" }, { "unordered-list": "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  ["clean"],
];
onMounted(() => {
  const ele = element.value;
  if (!ele || !(ele instanceof HTMLElement)) {
    throw new Error("Element not found");
  }
  quill.create(ele, {
    theme: props.inline ? "bubble" : "snow",
    placeholder: props.placeholder,
    readOnly: props.disabled,
    modules: {
      toolbar: toolbarOptions,
    },
  });
  quill.setValue(props.modelValue ?? "");
  quill.instance.root.innerHTML = props.modelValue ?? "";

  // Automatically clean up empty paragraphs
  quill.instance.on("text-change", () => {
    const quillContent = quill.instance.root.innerHTML;

    if (quillContent === "<p><br></p>") {
      quill.instance.root.innerHTML = "";
    }
    modelValueInternal.value = quill.getValue();
  });

  // Set the icon for unordered list
  quill.instance.on("editor-change", () => {
    const unorderedList =
      quill.instance.container.parentElement?.querySelector(
        ".ql-unordered-list",
      );

    if (unorderedList) {
      unorderedList.innerHTML = UnorderedListItem.icon;
    }
  });
});

const smtColorLink = ref(props.inlineCss.smtColorLink ?? "initial");
const smtFontSize = ref(props.inlineCss.smtFontSize ?? "initial");
const smtFontText = ref(props.inlineCss.smtFontText ?? "initial");
const smtColorText = ref(props.inlineCss.smtColorText ?? "initial");
const smtFontHeader = ref(props.inlineCss.smtFontHeader ?? "initial");
const smtColorHeader = ref(props.inlineCss.smtColorHeader ?? "initial");
const smtLinkTextDecoration = ref(
  props.inlineCss.smtLinkTextDecoration ?? "initial",
);
const smtLinkFontWeight = ref(props.inlineCss.smtLinkFontWeight ?? "initial");

watch(
  () => props.inlineCss,
  (newVal) => {
    smtColorLink.value = newVal.smtColorLink ?? smtColorLink.value;
    smtFontSize.value = newVal.smtFontSize ?? smtFontSize.value;
    smtFontText.value = newVal.smtFontText ?? smtFontText.value;
    smtColorText.value = newVal.smtColorText ?? smtColorText.value;
    smtFontHeader.value = newVal.smtFontHeader ?? smtFontHeader.value;
    smtColorHeader.value = newVal.smtColorHeader ?? smtColorHeader.value;
    smtLinkTextDecoration.value =
      newVal.smtLinkTextDecoration ?? smtLinkTextDecoration.value;
    smtLinkFontWeight.value =
      newVal.smtLinkFontWeight ?? smtLinkFontWeight.value;
  },
);
</script>

<style>
.ql-editor:focus-visible {
  outline: none !important;
}

.ql-editor {
  padding: 0 !important;
  flex: 1;
}

.ql-editor * {
  font-size: v-bind(smtFontSize);
  font-family: v-bind(smtFontText);
  color: v-bind(smtColorText);
}

.ql-editor p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.ql-editor h1 {
  font-size: 1.8rem !important;
}

.ql-editor h2 {
  font-size: 1.6rem !important;
}

.ql-editor h3 {
  font-size: 1.4rem !important;
}

.ql-editor h4 {
  font-size: 1.2rem !important;
}

.ql-editor h5 {
  font-size: 1.1rem !important;
}

.ql-editor h6 {
  font-size: 1rem !important;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  color: v-bind(smtColorHeader);
  font-family: v-bind(smtFontHeader);
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.ql-editor ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ql-editor ol ol {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-editor ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ql-editor ul ul {
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-editor a {
  color: v-bind(smtColorLink);
  text-decoration: v-bind(smtLinkTextDecoration);
  font-weight: v-bind(smtLinkFontWeight);
  background-color: transparent;
}

.ql-editor a:hover {
  text-decoration: underline;
}

.ql-tooltip {
  z-index: 999 !important;
}

/* START: Fix for https://github.com/slab/quill/issues/4103 */
.ql-editor ol li[data-list="bullet"] {
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-editor ul li[data-list="bullet"] {
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-editor li[data-list="bullet"] > .ql-ui:before {
  content: "";
}

.ql-editor ol li[data-list="ordered"] {
  list-style-type: decimal;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-editor ul li[data-list="ordered"] {
  list-style-type: decimal;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ql-editor li[data-list="ordered"] > .ql-ui:before {
  content: "";
}
/* END: Fix */
</style>
