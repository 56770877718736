<template>
  <div class="flex flex-col gap-8">
    <LayoutTwoCol class="items-center">
      <template v-slot:left>
        <Search v-model="search" :placeholder="texts.actions.search" />
      </template>

      <div class="flex items-center justify-between">
        <span class="font-semibold text-deepteal-700"
          >{{ filteredMailings.length }} {{ texts.generic.results }}</span
        >
        <ButtonAdd @click="mailingTypePickerVisible = true" />
      </div>
    </LayoutTwoCol>
    <Loader v-if="loading" />
    <ErrorComponent v-else-if="error" />
    <div v-else-if="filteredMailings.length > 0" class="flex flex-col gap-12">
      <template v-for="(group, key) in groupedMailings" :key="key">
        <List v-if="group.length > 0" :title="texts.enums.mailingStatus[key]">
          <RouterLinkAuth
            v-for="mailing in group"
            :key="mailing.id"
            v-slot="{ navigate }"
            :to="{
              name: RouteNamesActivityOverview.MAILING_OVERVIEW,
              params: { mailingId: mailing.id },
            }"
            custom
          >
            <MailingItem :mailing="mailing" @click="navigate" />
          </RouterLinkAuth>
        </List>
      </template>
    </div>
    <MailingTypePicker
      v-model:visible="mailingTypePickerVisible"
      :title="texts.navigationItems.mailings.mailingTypePicker.title"
      :description="
        texts.navigationItems.mailings.mailingTypePicker.description
      "
      :mailingTypeGroups="mailingTypeGroups"
    ></MailingTypePicker>
  </div>
</template>

<script setup lang="ts">
import { MailingStatus, MailingType, SessionStorageKey } from "@/enums";
import { computed, ref } from "vue";

// Components
import logger from "@/plugins/logger";
import List from "@/components/common/list/List.vue";
import MailingItem from "@/views/mailings/components/MailingItem.vue";
import { ActivityRelatedMailingOverviewDTO } from "@/lib/eduConfigurationServiceClient";
import activityContext from "@/store/context/activity.context";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import LayoutTwoCol from "@/components/common/layout/LayoutTwoCol.vue";
import Search from "@/components/common/search/Search.vue";
import { watch } from "vue";
import {
  conceptSortFn,
  plannedSortFn,
  sentSortFn,
} from "@/views/mailings/MainPage.types";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { RouteNamesActivityOverview } from "@/router/routeNames";
import Loader from "@/components/common/loader/Loader.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import texts from "@/utils/texts";
import ButtonAdd from "@/components/common/button/ButtonAdd.vue";
import MailingTypePicker from "@/components/mailing-type-picker/MailingTypePicker.vue";
import settings from "@/store/context/settings.context";
import {
  MailingTypeGroup,
  toMailingTypeGroups,
} from "@/components/mailing-type-picker/MailingTypePicker.types";
import { useRoute } from "vue-router";

const props = defineProps<{ id: string }>();

const mailings = ref<ActivityRelatedMailingOverviewDTO[]>([]);

const search = ref<string | undefined>(
  sessionStorage.getItem(SessionStorageKey.ActivityMailingSearchTerm) ||
    undefined,
);

watch(search, (newValue) =>
  newValue
    ? sessionStorage.setItem(
        SessionStorageKey.ActivityMailingSearchTerm,
        newValue,
      )
    : sessionStorage.removeItem(SessionStorageKey.ActivityMailingSearchTerm),
);

const filteredMailings = computed<ActivityRelatedMailingOverviewDTO[]>(() =>
  mailings.value.filter((mailing: ActivityRelatedMailingOverviewDTO) =>
    search.value
      ? mailing.name.toLowerCase().includes(search.value?.toLowerCase())
      : true,
  ),
);
// Loading & Error
const loading = ref<boolean>(true);
const error = ref<boolean>(false);

const groupedMailings = computed<
  Record<
    "Sent" | "Planned" | "Concept" | "Failed",
    ActivityRelatedMailingOverviewDTO[]
  >
>(() => ({
  [MailingStatus.Planned]: plannedMailings.value,
  [MailingStatus.Concept]: conceptMailings.value,
  [MailingStatus.Sent]: sentMailings.value,
  [MailingStatus.Failed]: failedMailings.value,
}));

const plannedMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Planned ||
        mailing.status === MailingStatus.InProgress,
    )
    .toSorted(plannedSortFn),
);
const sentMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Sent,
    )
    .toSorted(sentSortFn),
);

const failedMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Failed,
    )
    .toSorted(sentSortFn),
);

const conceptMailings = computed(() =>
  filteredMailings.value
    .filter(
      (mailing: ActivityRelatedMailingOverviewDTO) =>
        mailing.status === MailingStatus.Concept,
    )
    .toSorted(conceptSortFn),
);

const mailingTypePickerVisible = ref(false);

const route = useRoute();

const mailingTypeGroups: MailingTypeGroup[] = toMailingTypeGroups(
  settings.mailingTypes.filter((mt) => mt.mailingType !== MailingType.General),
  {
    useDashboardSelection: false,
    returnUrl: route.path,
    activityId: props.id,
  },
);

if (!activityContext.activity) {
  throw new Error("no activity id found");
}
eduConfigurationServiceClient
  .getMailings(props.id, false)
  .then((res) => {
    mailings.value = res;
  })
  .catch((e) => {
    error.value = true;
    logger.log(e);
  })
  .finally(() => {
    loading.value = false;
  });
</script>
